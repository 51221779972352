import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import moment from "moment";

import {isEmpty} from "../../../core/components/DLComponentHelper";
import {APP_CONST} from "../../../AppComponents/AppConstants";
import DLLoader from "../../../core/components/DLLoader/DLLoader";

import LeadListView from "./LeadsListView";
import {renderStatusBlock, renderStatusDot} from "../../LeadsHelper";
import {
    getLeadsListing, setSearchKeyword, setFromDate,
    setToDate, setStatus, resetLeadFilters, getLeadSources,
    setSkipCount, setLimitCount, setPageCount, setLeadsRowPerPageCount
} from "../../LeadsActions";
import appTheme from "../../../assets/appTheme";

const LeadList = (props) => {
    const [leadListState, setLeadListState] = useState({
        leads: [],
        listingCount: 0,
        skipCount: 0,
        limitCount: 10,
        isShowFilterMobile: false,
        isCreateModalOpen: false,

        UIState: '',
        errorInApi: '',
        isShowLoader: false,
        filters: {
            keyword: '',
            fromDate: '',
            toDate: '',
            status: []
        },
        sourceNames: []
    });

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    useEffect(() => {
        getSourceNameList();
    }, [props.leadsFilter]);

    useEffect(() => {
        getLeads();
    }, [props.leadsFilter]);

    const getSourceNameList = () => {
        setLeadListState((prevStep) => ({
            ...prevStep,
            isShowLoader: true
        }));
        getLeadSources().then((res) => {
            if (res?.success) {
                setLeadListState((prevStep) => ({
                    ...prevStep,
                    sourceNames: res.data?.sources,
                    isShowLoader: false
                }));
            } else {
                setLeadListState((prevStep) => ({
                    ...prevStep,
                    isShowLoader: false
                }));
            }
        })
    };

    const generateFilter = (filter) => {
        let filterObject = {};
        if (!isEmpty(filter.keyword)) {
            filterObject.keyword = filter.keyword;
        }
        if (!isEmpty(filter.toDate)) {
            filterObject.toDate = filter.toDate;
        }
        if (!isEmpty(filter.fromDate)) {
            filterObject.fromDate = filter.fromDate;
        }
        if (!isEmpty(filter.status)) {
            if (filter.status[0] === "ALL") {
                delete filterObject.status
            } else {
                filterObject.status = filter.status;
            }
        }
        return filterObject;
    };

    const getLeads = () => {
        const {profile, leadsFilter} = props;

        setLeadListState(prevState => ({
            ...prevState,
            isShowLoader: true
        }));

        let payload = {
            "skipCount": leadsFilter.skipCount,
            "limitCount": leadsFilter.limitCount,
            "filters": generateFilter(leadsFilter)
        };

        getLeadsListing(payload, profile.advisoryId)
            .then(res => {
                if (res?.success) {
                    setLeadListState(prevState => ({
                        ...prevState,
                        leads: res.data?.leadRequests,
                        listingCount: res.data?.count,
                        skipCount: leadsFilter.skipCount,
                        limitCount: leadsFilter.limitCount,
                        isShowLoader: false,
                        UIState: APP_CONST.CONTENT_AVAILABLE,
                    }));
                    setPage(isEmpty(leadsFilter.page) ? 0 : leadsFilter.page);
                    setRowsPerPage(isEmpty(leadsFilter.rowsPerPage) ? 10 : leadsFilter.rowsPerPage);
                } else {
                    setLeadListState(prevState => ({
                        ...prevState,
                        isShowLoader: false,
                        UIState: APP_CONST.CONTENT_UNAVAILABLE,
                    }));
                }
            })
    };

    // Function to determine whether to enable/disable the pagination control buttons
    const getPaginationNextButtonStatus = () => {
        const {listingCount, skipCount, limitCount} = leadListState;
        let val = false;
        if (listingCount <= rowsPerPage) {
            val = true;
        } else if ((skipCount + limitCount) >= listingCount) {
            val = true;
        }
        return val;
    };

    const onChangeFilter = (name, value) => {
        resetPagination();
        if (name === 'keyword') {
            setLeadListState((prevStep) => ({
                ...prevStep,
                filters: {
                    ...prevStep.filters,
                    keyword: value,
                }
            }));
            setSearchKeyword(value);
            return;
        }

        if (name === 'fromDate') {
            let fromDate = isEmpty(value) ? null : moment(value).format('YYYY-MM-DD');
            setLeadListState((prevStep) => ({
                ...prevStep,
                filters: {
                    ...prevStep.filters,
                    fromDate: fromDate
                }
            }));
            setFromDate(fromDate);
            return;
        }

        if (name === 'toDate') {
            let toDate = isEmpty(value) ? null : moment(value).format('YYYY-MM-DD');
            setLeadListState((prevStep) => ({
                ...prevStep,
                filters: {
                    ...prevStep.filters,
                    toDate: toDate,
                }
            }));
            setToDate(toDate);
            return;
        }

        if (name === 'status') {
            setLeadListState((prevStep) => ({
                ...prevStep,
                filters: {
                    ...prevStep.filters,
                    status: [value]
                }
            }));
            setStatus([value]);
        }
    };

    const resetPagination = () => {
        setLeadsRowPerPageCount(10);
        setLimitCount(10);
        setPageCount(0);
        setSkipCount(0);
    };

    const handleChangeRowsPerPage = (event) => {
        setLeadListState(prevState => ({
            ...prevState,
            isSearchFilterUpdated: true
        }));

        setLeadsRowPerPageCount(parseInt(event.target.value, 10));
        setLimitCount(parseInt(event.target.value, 10));
        setPageCount(0);
        setSkipCount(0);
    };

    const handleChangePage = (event, newPage) => {
        let {limitCount, skipCount} = leadListState;
        const isForward = newPage > page;
        if (isForward) {
            skipCount = skipCount + rowsPerPage;
            limitCount = rowsPerPage
        } else {
            skipCount = skipCount - rowsPerPage;
            limitCount = rowsPerPage
        }

        setSkipCount(skipCount);
        setLimitCount(limitCount);
        setPageCount(newPage);
    };

    const handleToggleMobileFilter = (value) => {
        setLeadListState((prevStep) => ({
            ...prevStep,
            isShowFilterMobile: value
        }));
    };

    const resetLeadListing = () => {
        resetLeadFilters();
    };

    const onSaveSuccess = () => {
        getLeads();
    };

    const getSourceName = (id) => {

        const {sourceNames} = leadListState;
        if (id === 'NONE') {
            return 'None'
        }
        const selectedSource = sourceNames.find(source => source.id === id);
        return selectedSource ? selectedSource.name : "None";
    };

    const getStatusDotUI = (status, fullName) => {
        if (isEmpty(status)) {
            return '-'
        }
        //[status] returns respective class of status
        const statusClass = {
            NOT_CONTACTED: 'warning',
            ON_HOLD: 'warning',
            LOST: 'danger',
        }[status] || '';

        return (
            <div className="d-flex">
                <div className="d-flex justify-content-center align-items-center">
                    <div className="d-flex align-items-center">
                        {status === 'NOT_CONTACTED' && <span className={`status-dot ${statusClass}`}/>}
                        {status === 'ON_HOLD' &&
                        <i className="fa-solid fa-pause me-2" style={{color: appTheme.appColor.grayDark}}/>}
                        {status === 'LOST' &&
                        <i className="fa-solid fa-xmark me-2 " style={{color: appTheme.appColor.danger}}/>}
                        {status === "ONBOARDING_INITIATED" &&
                        <i className="fa-solid fa-check me-2 " style={{color: appTheme.appColor.success}}/>}
                        {status === "ONBOARDING_COMPLETED" &&
                        renderStatusDot(status)}
                        {status === "CONTACTED" &&
                        renderStatusDot(status)}
                    </div>
                </div>
                {`${fullName}`}
            </div>
        );
    };
    const getFormattedTableData = (leads) => {
        let tableData = [];
        const {isXSView} = props;
        if (isEmpty(leads)) {
            return tableData
        }

        leads.map((selectedLead) => {

            let leadRecords = [];
            let leadDetails = {};
            if (!isXSView) {
                leadRecords.push(selectedLead?.lead?.fullName);
                leadRecords.push(getSourceName(selectedLead.referer?.sourceType));
                if (isEmpty(selectedLead.assignedTS)) {
                    leadRecords.push('-');
                } else {
                    leadRecords.push(moment(selectedLead.assignedTS).format("DD-MM-YYYY"))
                }
                // Rendering custom ui here for the below column
                leadRecords.push({
                    isCustomUI: true,
                    customUI: renderStatusBlock(selectedLead?.status)
                });
            } else {
                // Rendering custom ui here for the below column
                leadRecords.push({
                    isCustomUI: true,
                    customUI: getStatusDotUI(selectedLead?.status, selectedLead?.lead.fullName)
                });
                if (isEmpty(selectedLead.assignedTS)) {
                    leadRecords.push('-');
                } else {
                    leadRecords.push(moment(selectedLead.assignedTS)?.format("DD-MM-YYYY"));
                }
            }

            leadDetails.id = selectedLead.id;
            leadDetails.data = leadRecords;
            tableData.push(leadDetails)
        });

        return tableData

    };

    const handleRefreshButton=()=>{
        getSourceNameList();
        getLeads();
    };

    const redirectToDetailsScreen = (leadId) => {
        props.history.push('/leads/' + leadId)
    };

    if (isEmpty(leadListState.UIState)) {
        return (<DLLoader type={"screen"} isVisible={true}/>);
    }

    return (
        <LeadListView
            {...props}
            {...leadListState}
            page={page}
            rowsPerPage={rowsPerPage}
            handleToggleMobileFilter={handleToggleMobileFilter}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            getPaginationNextButtonStatus={getPaginationNextButtonStatus}
            getLeads={getLeads}
            handleRefreshButton={handleRefreshButton}
            onChangeFilter={onChangeFilter}
            onSaveSuccess={onSaveSuccess}
            resetLeadListing={resetLeadListing}
            getFormattedTableData={getFormattedTableData}
            redirectToDetailsScreen={redirectToDetailsScreen}
            getSourceName={getSourceName}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile,
    leadsFilter: state.leadState.leadsFilter,
    isMDView: state.appState.deviceInfo.isMDView,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isSMView: state.appState.deviceInfo.isSMView,
    isXSView: state.appState.deviceInfo.isXSView,
});

export default connect(mapStateToProps, {})(LeadList);
