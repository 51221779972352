import React, {useEffect} from 'react';

import DLButton from "../../../../core/components/DLButton/DLButton";
import DLRadioGroup from "../../../../core/components/DLRadioGroup/DLRadioGroup";
import DLInputTextField from "../../../../core/components/DLInputTextField/DLInputTextField";
import DLDropDown from "../../../../core/components/DLDropdown/DLDropDown";
import DLText from "../../../../core/components/DLText/DLText";

import {isEmpty} from "../../../../utils/validations";
import {debounce, getRandomId} from "../../../../core/components/DLComponentHelper";

import Dropdown from "react-bootstrap/Dropdown";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";

const NFTCreateView = (props) => {

    const {
        history, handleCreate, onChangeFormData, onEnterKeyPress,
        modalErrorMessage, errors, requestType, isXSView, scrollToFieldId, onBlurInputText, comment, name,
        phoneNumber, email, dropdownValues, category, clients, keyword, handleClick, isOptionSelected, setCategoryValues
    } = props;

    useEffect(() => {
        if (!isEmpty(scrollToFieldId)) {
            const element = document.getElementById(scrollToFieldId);
            if (!isEmpty(element)) {
                element.scrollIntoView({behavior: 'smooth', block: 'center'});
                handleScrollChangeWithDebounce()
            }
        }
        return () => {
            window.scrollTo(0, 0)
        }
    }, [props.scrollToFieldId]);

    const handleScrollChangeWithDebounce = debounce(() => {
        props.onChangeFormData('scrollToFieldId', '');
    }, 500);

    const getUi = () => {

        if (isEmpty(clients)) {
            return (
                <div className="w-100" style={{height: 'calc(100vh - 535px)'}}>
                    <div className="d-flex justify-content-center align-items-center h-100">
                        <DLText id={""}
                                text={"No match found."}
                                fontColor={"lightGray"}
                                marginBottom={"none"}
                        />
                    </div>
                </div>
            );
        }
        return (
            <div>
                {!isEmpty(clients) && <div>
                    {clients.map((item, index) => {
                        return (
                            <Dropdown.Item onClick={() => handleClick(item)} key={getRandomId()}>
                                <div className={index === 0 ? "p-2 " : "p-2 border-top"}>
                                    <DLText id={""}
                                            text={item?.fullName}
                                            fontWeight={"normal"}
                                            fontSize={"xs"}
                                            marginBottom={"none"}
                                    />
                                    <DLText id={""}
                                            text={item?.IINNumber}
                                            fontWeight={"normal"}
                                            fontSize={"xs"}
                                            fontColor={"grayDark"}
                                            marginBottom={"none"}
                                    />
                                </div>
                            </Dropdown.Item>
                        )
                    })}
                </div>}
            </div>
        );
    }

    const renderSearchBar = () => {
        const {handleSearch} = props;

        return (
            <div className='d-flex justify-content-center align-items-center'>
                <div className={"w-100"}>
                    <div className="resappbar-search-box-container" style={{flex: 1}}>
                        <Dropdown className='w-100 p-0'>
                            <Dropdown.Toggle id="" className='py-1 '>
                                <div className="w-100"
                                     style={{color: "var(--gray-light)"}}
                                >
                                    <Paper
                                        className="res-app-paper"
                                        component=""
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            width: '100%',
                                            boxShadow: "none",
                                            p: 0
                                        }}
                                    >
                                        <InputBase
                                            sx={{flex: 1, p: 0, fontSize: '14px', fontWeight: 500}}
                                            fullWidth
                                            onChange={(e) => handleSearch(e.target.value)}
                                            autoFocus={true}
                                            placeholder={"Search by name"}
                                            value={keyword}

                                        />
                                        {
                                            <SearchIcon/>
                                        }
                                    </Paper>
                                </div>
                            </Dropdown.Toggle>
                        </Dropdown>
                    </div>
                    {(!isEmpty(keyword) && !isOptionSelected) &&
                    <div className='resappbar-search-box-container-options p-2'>
                        <div className={"flex-grow-1 pt-3"}>
                            {getUi()}
                        </div>
                    </div>
                    }
                </div>
            </div>
        )
    };

    const renderRequestTextUi = () => {

        return (
            <div>
                {requestType !== "ARN_CHANGE" && <div className='row mb-3'>
                    <div className='col-12' id={'search-field'}>
                        <DLText id={''}
                                text={'Search'}
                                fontColor={"grayDark"}
                                fontWeight={"normal"}
                                fontSize={"xs"}
                        />
                        {renderSearchBar()}
                        {errors.name !== '' &&
                            <div className='ps-2'>
                                <DLText id={''}
                                        text={'Please select client'}
                                        fontColor={"danger"}
                                        fontSize={"xs"}
                                />
                            </div>
                        }
                    </div>
                </div>}
                {isOptionSelected &&
                <div>
                    <div className='row'>
                        <div className='col-12 mb-3' id={'name-field'}>
                            <DLText id={''}
                                    text={"Name"}
                                    fontSize={"xs"}
                                    fontColor={"grayDark"}
                            />
                            <DLText id={''}
                                    text={name}
                                    fontSize={"sm"}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-6 mb-3' id={'email-field'}>
                            <DLText id={''}
                                    text={"Email"}
                                    fontSize={"xs"}
                                    fontColor={"grayDark"}
                            />
                            <DLText id={''}
                                    text={email}
                                    fontSize={"sm"}
                            />
                        </div>
                        <div className='col-6 mb-3' id={'phoneNumber-field'}>
                            <DLText id={''}
                                    text={"Phone Number"}
                                    fontSize={"xs"}
                                    fontColor={"grayDark"}
                            />
                            <DLText id={''}
                                    text={phoneNumber}
                                    fontSize={"sm"}
                            />
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className='col-12' id={'category-field'}>
                            <DLText id={''}
                                    text={"Category"}
                                    fontSize={"xs"}
                                    fontColor={"grayDark"}
                            />
                            <DLText id={''}
                                    text={setCategoryValues(category)}
                                    fontSize={"sm"}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12' id={'comment-field'}>
                            <DLInputTextField
                                id={'comment-field'}
                                label={'Comment'}
                                value={comment}
                                marginBottom={"sm"}
                                inputType={"text"}
                                isMulti={true}
                                rows={3}
                                maxRows={4}
                                maxCharLength={500}
                                disableHelperMessageHeight={true}
                                hideCharLength={true}
                                isClearable={false}
                                isRequired={true}
                                error={errors.comment !== ''}
                                helperMessage={errors.comment}
                                onChangeValue={(val) => {
                                    onChangeFormData('comment', val)
                                }}
                                onBlur={() => {
                                    onBlurInputText('comment')
                                }}
                            />
                        </div>
                    </div>
                </div>}
            </div>
        )
    }

    const renderRequestUi = () => {
        return (
            <div>
                {requestType !== "ARN_CHANGE" && <div className='row mb-3'>
                    <div className='col-12' id={'search-field'}>
                        <DLText id={''}
                                text={'Search'}
                                fontColor={"grayDark"}
                                fontWeight={"normal"}
                                fontSize={"xs"}
                        />
                        {renderSearchBar()}
                    </div>
                </div>}
                <div className='row'>
                    <div className='col-12' id={'name-field'}>
                        <DLInputTextField
                            id={'name-field'}
                            label={'Name'}
                            value={name}
                            marginBottom={"sm"}
                            inputType={"text"}
                            disableHelperMessageHeight={true}
                            hideCharLength={true}
                            isClearable={false}
                            isRequired={true}
                            maxCharLength={60}
                            error={errors.name !== ''}
                            helperMessage={errors.name}
                            onChangeValue={(val) => {
                                onChangeFormData('name', val)
                            }}
                            onBlur={() => {
                                onBlurInputText('name')
                            }}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-6' id={'email-field'}>
                        <DLInputTextField
                            id={'email-field'}
                            label={'Email'}
                            value={email}
                            marginBottom={"sm"}
                            inputType={"text"}
                            maxCharLength={50}
                            disableHelperMessageHeight={true}
                            hideCharLength={true}
                            isClearable={false}
                            isRequired={true}
                            error={errors.email !== ''}
                            helperMessage={errors.email}
                            onChangeValue={(val) => {
                                onChangeFormData('email', val)
                            }}
                            onBlur={() => {
                                onBlurInputText('email')
                            }}
                        />
                    </div>
                    <div className='col-6' id={'phoneNumber-field'}>
                        <DLInputTextField
                            id={'phone-field'}
                            label={'Phone Number'}
                            value={phoneNumber}
                            marginBottom={"sm"}
                            inputType={"number"}
                            disableHelperMessageHeight={true}
                            hideCharLength={true}
                            isClearable={false}
                            isRequired={true}
                            maxCharLength={10}
                            error={errors.phoneNumber !== ''}
                            helperMessage={errors.phoneNumber}
                            onChangeValue={(val) => {
                                onChangeFormData('phoneNumber', val)
                            }}
                            onBlur={() => {
                                onBlurInputText('phoneNumber')
                            }}
                        />
                    </div>
                </div>
                <div className='row mb-3'>
                    <div className='col-12' id={'category-field'}>
                        <DLDropDown
                            id={"category-filter"}
                            label={'Category'}
                            labelFontColor={'grayDark'}
                            labelFontSize={'xs'}
                            value={category}
                            minWidth={"100%"}
                            options={
                                dropdownValues.categoryOptions
                            }
                            onSelect={(e) => {
                                onChangeFormData('category', e)
                            }}
                            isRequired={true}
                            error={errors.category !== ''}
                            disableHelperMessageHeight={true}
                            helperMessage={errors.category}
                            marginBottom={"none"}
                            labelPlacement={'top'}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12' id={'comment-field'}>
                        <DLInputTextField
                            id={'comment-field'}
                            label={'Comment'}
                            value={comment}
                            marginBottom={"sm"}
                            inputType={"text"}
                            isMulti={true}
                            rows={3}
                            maxRows={4}
                            maxCharLength={500}
                            disableHelperMessageHeight={true}
                            hideCharLength={true}
                            isClearable={false}
                            isRequired={true}
                            error={errors.comment !== ''}
                            helperMessage={errors.comment}
                            onChangeValue={(val) => {
                                onChangeFormData('comment', val)
                            }}
                            onBlur={() => {
                                onBlurInputText('comment')
                            }}
                        />
                    </div>
                </div>
            </div>
        )
    }

    const renderModalError = () => {
        if (modalErrorMessage) {
            return (
                <div className='py-2 align-items-center d-flex message-block'>
                    <div className='error-vertical-line'/>
                    <div className='error-panel'>
                        {modalErrorMessage}
                    </div>
                </div>
            )
        }
    };

    const renderButton = () => {
        return (
            <div className="sticky-bottom-buttons gap-0">
                <DLButton
                    id={'raise-request-cancelBtn'}
                    history={history}
                    label={'Cancel'}
                    buttonSize={"sm"}
                    fontSize={"md"}
                    styleType={"outlined"}
                    backgroundColor={"grey"}
                    sideMargin={"sm"}
                    onClick={() => props.history.push("/NF-transactions")}/>
                <DLButton
                    id={'raise-request-saveBtn'}
                    history={props.history}
                    label={'Raise'}
                    buttonSize={"sm"}
                    fontSize={"md"}
                    sideMargin={"sm"}
                    onClick={() => handleCreate()}
                />
            </div>
        )
    };

    const renderDesktopHeader = () => {
        return (
            <div className='w-100'>
                <div
                    className={isXSView ? 'ps-3 page-header p-0 bg-white' : 'page-header d-flex justify-content-between'}>
                    <div className="d-flex align-items-center">
                        <i className='fa-solid fa-chevron-left cursor fa-2xs'
                           style={{width: 10}}
                           onClick={() => {
                               props.history.goBack()
                           }}
                        />
                        <div className="ps-2">
                            Raise Request
                        </div>
                    </div>

                </div>
            </div>
        )
    };

    const renderDesktopData = () => {

        return (
            <div className=" "
                 style={{maxHeight: 'calc(100dvh - 154px)', minHeight: 'calc(100dvh - 154px)', overflow: "auto"}}>
                <div className="bg-white row border-rounded p-3 m-0" style={{minHeight: 'calc(100dvh - 154px)'}}>
                    <div className="col-lg-6 ">
                        <form onKeyPress={(event) => onEnterKeyPress(event)}>
                            {renderModalError()}
                            <div className='row'
                                 id={'name-field'}>
                                <div className='col-12 p-0' id={'requestType-field'}>
                                    <DLText id={''}
                                            text={'Request Type'}
                                            fontColor={"grayDark"}
                                            fontSize={"xs"}
                                            fontWeight={"normal"}
                                    />
                                    <DLRadioGroup
                                        id={'hours-radioBtn'}
                                        label={''}
                                        type={'block'}
                                        radioType={'multiple'}
                                        options={dropdownValues?.NFTRequestOptions}
                                        value={requestType}
                                        disableHelperMessageHeight
                                        disableDescriptionMessageHeight
                                        marginBottom={'xs'}
                                        helperMessageColor={'danger'}
                                        helperMessage={errors.requestType}
                                        error={errors.requestType !== ''}
                                        isRequired={true}
                                        onChangeValue={(e) => {
                                            onChangeFormData('requestType', e)
                                        }}
                                    />
                                </div>
                                <div className='col-12 p-0'>
                                    {requestType === "ARN_CHANGE" && renderRequestUi()}
                                    {requestType !== "ARN_CHANGE" && renderRequestTextUi()}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </div>)
    };

    const renderDesktopUI = () => {
        return (
            <div className={isXSView ? '' : 'lead-details-container '}>
                {renderDesktopHeader()}
                <div className={isXSView ? 'p-0' : 'lead-page-container page-container bg-light mx-3 '}>
                    {renderDesktopData()}
                </div>
                {renderButton()}
            </div>
        );
    };

    return (
        <div>
            {renderDesktopUI()}
        </div>
    );
};

export default NFTCreateView;