import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import moment from "moment";

import {isEmpty} from "../../../../../core/components/DLComponentHelper";
import {APP_CONST} from "../../../../../AppComponents/AppConstants";

import {
    setSearchKeyword, setFromDate,
    setToDate, setStatus, resetNFTFilters,
    setSkipCount, setLimitCount, setPageCount, setLeadsRowPerPageCount, getTransactionListing, setTypeOfRequest
} from "../../../NFTAction";

import DLLoader from "../../../../../core/components/DLLoader/DLLoader";
import NFTListForCRMView from "./NFTListForCRMView";

const NFTListForCRM = (props) => {
    const [NFTState, setNFTState] = useState({
        transactions: [],
        listingCount: 0,
        skipCount: 0,
        limitCount: 10,
        isShowFilterMobile: false, //CHECK
        isCreateModalOpen: false, //CHECK

        UIState: '',
        errorInApi: '',
        isShowLoader: false,
        filters: {
            keyword: '',
            fromDate: '',
            toDate: '',
            requestType: '',
            status: []
        },
        sourceNames: []
    });
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    useEffect(() => {
        getTransactions();
    }, [props.NFTFilter]);

    const generateFilter = (filter) => {
        let filterObject = {};
        if (!isEmpty(filter.keyword)) {
            filterObject.keyword = filter.keyword;
        }
        if (!isEmpty(filter.toDate)) {
            filterObject.toDate = filter.toDate;
        }
        if (!isEmpty(filter.fromDate)) {
            filterObject.fromDate = filter.fromDate;
        }
        if (!isEmpty(filter.status)) {
            if (filter.status[0] === "ALL") {
                delete filterObject.status
            } else {
                filterObject.status = filter.status;
            }
        }
        if (!isEmpty(filter.requestType)) {
            if (filter.requestType[0] === "ALL") {
                delete filterObject.requestType
            } else {
                filterObject.requestType = filter.requestType;
            }
        }
        return filterObject;
    };

    const getTransactions = () => {
        const {profile, NFTFilter} = props;

        setNFTState(prevState => ({
            ...prevState,
            isShowLoader: true
        }));

        let payload = {
            "skipCount": NFTFilter.skipCount,
            "limitCount": NFTFilter.limitCount,
            "filters": generateFilter(NFTFilter)
        };

        getTransactionListing(payload, profile.advisoryId)
            .then(res => {
                if (res?.success) {
                    setNFTState(prevState => ({
                        ...prevState,
                        transactions: res?.data?.nftRequests,
                        listingCount: res.data?.count,
                        skipCount: NFTFilter.skipCount,
                        limitCount: NFTFilter.limitCount,
                        isShowLoader: false,
                        UIState: APP_CONST.CONTENT_AVAILABLE,
                    }));
                    setPage(isEmpty(NFTFilter.page) ? 0 : NFTFilter.page);
                    setRowsPerPage(isEmpty(NFTFilter.rowsPerPage) ? 10 : NFTFilter.rowsPerPage);
                } else {
                    setNFTState(prevState => ({
                        ...prevState,
                        isShowLoader: false,
                        UIState: APP_CONST.CONTENT_UNAVAILABLE,
                    }));
                }
            })
    };

    // Function to determine whether to enable/disable the pagination control buttons
    const getPaginationNextButtonStatus = () => {
        const {listingCount, skipCount, limitCount} = NFTState;
        let val = false;
        if (listingCount <= rowsPerPage) {
            val = true;
        } else if ((skipCount + limitCount) >= listingCount) {
            val = true;
        }
        return val;
    };

    const onChangeFilter = (name, value) => {
        resetPagination();
        if (name === 'keyword') {
            setNFTState((prevStep) => ({
                ...prevStep,
                filters: {
                    ...prevStep.filters,
                    keyword: value,
                }
            }));
            setSearchKeyword(value);
            return;
        }

        if (name === 'fromDate') {
            let fromDate = isEmpty(value) ? null : moment(value).format('YYYY-MM-DD');
            setNFTState((prevStep) => ({
                ...prevStep,
                filters: {
                    ...prevStep.filters,
                    fromDate: fromDate
                }
            }));
            setFromDate(fromDate);
            return;
        }

        if (name === 'toDate') {
            let toDate = isEmpty(value) ? null : moment(value).format('YYYY-MM-DD');
            setNFTState((prevStep) => ({
                ...prevStep,
                filters: {
                    ...prevStep.filters,
                    toDate: toDate,
                }
            }));
            setToDate(toDate);
            return;
        }

        if (name === 'status') {
            setNFTState((prevStep) => ({
                ...prevStep,
                filters: {
                    ...prevStep.filters,
                    status: [value]
                }
            }));
            setStatus([value]);
        }

        if (name === 'requestType') {
            setNFTState((prevStep) => ({
                ...prevStep,
                filters: {
                    ...prevStep.filters,
                    requestType: [value]
                }
            }));
            setTypeOfRequest([value]);
        }

    };

    const resetPagination = () => {
        setLeadsRowPerPageCount(10);
        setLimitCount(10);
        setPageCount(0);
        setSkipCount(0);
    };

    const handleChangeRowsPerPage = (event) => {
        setNFTState(prevState => ({
            ...prevState,
            isSearchFilterUpdated: true
        }));

        setLeadsRowPerPageCount(parseInt(event.target.value, 10));
        setLimitCount(parseInt(event.target.value, 10));
        setPageCount(0);
        setSkipCount(0);
    };

    const handleChangePage = (event, newPage) => {
        let {limitCount, skipCount} = NFTState;
        const isForward = newPage > page;
        if (isForward) {
            skipCount = skipCount + rowsPerPage;
            limitCount = rowsPerPage
        } else {
            skipCount = skipCount - rowsPerPage;
            limitCount = rowsPerPage
        }

        setSkipCount(skipCount);
        setLimitCount(limitCount);
        setPageCount(newPage);
    };

    const handleToggleMobileFilter = (value) => {
        setNFTState((prevStep) => ({
            ...prevStep,
            isShowFilterMobile: value
        }));
    };

    const resetNFTListing = () => {
        resetNFTFilters();
    };

    const onSaveSuccess = () => {
        getTransactions();
    };

    const getLabel = (data, value) => {
        if (isEmpty(data) || isEmpty(value)) {
            return ''
        }
        const listingLabel = data.find(item => item.value === value);
        return listingLabel ? listingLabel.label : "";
    };

    const handleRefreshButton = () => {
        getTransactions();
    };

    if (isEmpty(NFTState.UIState)) {
        return (<DLLoader type={"screen"} isVisible={true}/>);
    }

    return (
        <NFTListForCRMView
            {...props}
            {...NFTState}
            page={page}
            rowsPerPage={rowsPerPage}
            handleToggleMobileFilter={handleToggleMobileFilter}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            getPaginationNextButtonStatus={getPaginationNextButtonStatus}
            getTransactions={getTransactions}
            handleRefreshButton={handleRefreshButton}
            onChangeFilter={onChangeFilter}
            onSaveSuccess={onSaveSuccess}
            resetNFTListing={resetNFTListing}
            getLabel={getLabel}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile,
    NFTFilter: state.NFTState.NFTFilter,
    isMDView: state.appState.deviceInfo.isMDView,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isSMView: state.appState.deviceInfo.isSMView,
    isXSView: state.appState.deviceInfo.isXSView,
});

export default connect(mapStateToProps, {})(NFTListForCRM);
