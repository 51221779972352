import {httpGet, httpPost, httpPut, httpDelete} from "../utils/httpHelper";
import {downloadS3BucketFile, uploadData} from "../utils/FileHelper";


export const getAllAccountTypes = () => {
    return httpGet('/service/advisory/accountType/all').then(res => {
        return res;
    })
};

// IIN Verification Api calls starts
export const searchAccount = (data,advisoryId,handleErrorInComponent) => {
    return httpPost('/service/advisory/account/search',data,advisoryId, handleErrorInComponent)
        .then(res => {
        return res;
    })
};

export const searchPersonByPAN = (data,advisoryId,handleErrorInComponent) => {
    return httpPost('/service/advisory/person/searchByPan', data, advisoryId, handleErrorInComponent)
        .then(res => {
        return res;
    })
};

export const initiateOnboarding = (data, advisoryId) => {
    return httpPost('/service/advisory/accountOpening/initiateOnboarding', data,advisoryId)
        .then(res => {
        return res;
    })
};
// IIN Verification Api calls ends

export const getPhoneEmailBelongsToList = (advisoryId) => {
    return httpGet('/service/advisory/relationship/all',advisoryId).then(res => {
        return res;
    })
};

export const getStateList = (advisoryId) => {
    return httpGet('/service/advisory/state/all',advisoryId).then(res => {
        return res;
    })
};

export const getOccupationList = (advisoryId) => {
    return httpGet('/service/advisory/occupation/all',advisoryId).then(res => {
        return res;
    })
};

export const getIndustryTypeList = (advisoryId) => {
    return httpGet('/service/advisory/industryType/all',advisoryId).then(res => {
        return res;
    })
};

export const getPoliticallyExposedOptions = (advisoryId) => {
    return httpGet('/service/advisory/pep/all',advisoryId).then(res => {
        return res;
    })
};

// FINANCIAL PERSONAL PROFILE API CALLS
export const updatePersonalProfileData = (data,advisoryId) => {
    return httpPut('/service/advisory/person/profile', data, advisoryId, true)
        .then((res) => {
            return res;
        })
};

export const getPersonalProfileData = (accountId,advisoryId) => {
    return httpGet('/service/advisory/person/profile?id='+accountId,advisoryId)
        .then(res => {
            return res;
        });
};

// FINANCIAL PROFILE PROFILE API CALLS
export const updateFinancialProfileData = (data,advisoryId) => {
    return httpPut('/service/advisory/account/financialProfile', data, advisoryId, true)
        .then((res) => {
            return res;
        });
};

export const getFinancialProfileData = (accountId,advisoryId) => {
    return httpGet('/service/advisory/account/financialProfile?accountId='+accountId,advisoryId)
        .then(res => {
            return res;
        });
};


export const getRiskProfileTypeOptions = (advisoryId) => {
    return httpGet('/service/advisory/riskProfileType/all',advisoryId).then(res => {
        return res;
    });
};

export const getApplicableIncomeOptions = (advisoryId) => {
    return httpGet('/service/advisory/applicableIncome/all',advisoryId).then(res => {
        return res;
    });
};

export const getCategoryOptions = (advisoryId) => {
    return httpGet('/service/advisory/categoryType/all',advisoryId).then(res => {
        return res;
    });
};

export const getAccountTypeOptions = (advisoryId) => {
    return httpGet('/service/advisory/accountType/all',advisoryId).then(res => {
        return res;
    });
};

export const getHoldingNatureOptions = (advisoryId) => {
    return httpGet('/service/advisory/holdingNature/all',advisoryId).then(res => {
        return res;
    })
};

export const getProfileOptions = (advisoryId) => {
    return httpGet('/service/advisory/financialProfile/all',advisoryId).then(res => {
        return res;
    })
};

export const getPersonalityTraitOptions = (advisoryId) => {
    return httpGet('/service/advisory/decisionMakingStyle/all',advisoryId).then(res => {
        return res;
    })
};

export const getDecisionApproachOptions = (advisoryId) => {
    return httpGet('/service/advisory/investmentStyle/all',advisoryId).then(res => {
        return res;
    })
};

export const getFinancialApproachOptions = (advisoryId) => {
    return httpGet('/service/advisory/moneyManagementStyle/all',advisoryId).then(res => {
        return res;
    })
};

export const getSpentOnRealEstateOptions = (advisoryId) => {
    return httpGet('/service/advisory/realEstateToNetworthRatio/all',advisoryId).then(res => {
        return res;
    })
};

export const getSpentOnInsuranceOptions = (advisoryId) => {
    return httpGet('/service/advisory/insuranceToIncomeRatio/all',advisoryId).then(res => {
        return res;
    })
};

export const getCashFlowOptions = (advisoryId) => {
    return httpGet('/service/advisory/cashflowType/all',advisoryId).then(res => {
        return res;
    })
};

export const getSpendingHabitsOptions = (advisoryId) => {
    return httpGet('/service/advisory/spendingHabit/all',advisoryId).then(res => {
        return res;
    })
};

export const getDetailsByIFSC = (data, advisoryId) => {
    return httpPost('/service/advisory/ifscCode/details',data,advisoryId, true)
        .then(res => {
        return res;
    })
};

// FINANCIAL BANK API CALLS
export const getBankAccountType = (advisoryId) => {
    return httpGet('/service/advisory/bank/type/all',advisoryId).then(res => {
        return res;
    })
};

export const updateBankAccountData = (data,advisoryId) => {
    return httpPut('/service/advisory/account/bank', data, advisoryId,true)
        .then((res) => {
            return res;
        })
};

export const getBankData = (accountId,advisoryId) => {
    return httpGet('/service/advisory/account/bank?accountId=' +accountId,advisoryId).then(res => {
        return res;
    })
};

export const getBanks = (advisoryId) => {
    return httpGet('/service/advisory/bank/all',advisoryId).then(res => {
        return res;
    })
};

export const getAllRequiredDocuments = (accountId, advisoryId) => {
    return httpGet('/service/advisory/account/documentRequirement?accountId='+accountId,advisoryId)
        .then(res => {
        return res;
    })
};

export const getProceedWithErrors = (onboardingApplicationId,advisoryId) => {
    return httpGet('/service/advisory/onboardingApplication/proceedWithErrors?onboardingApplicationId='+onboardingApplicationId,advisoryId)
        .then(res => {
        return res;
    })
};

export const setProceedWithErrors = (data,advisoryId) => {
    return httpPut('/service/advisory/onboardingApplication/proceedWithErrors',data, advisoryId)
        .then(res => {
        return res;
    })
};


//photo API Start //

export const uploadPhoto = (data, personId) => {
    let url='/service/media/upload/image?personId='+personId;
    return uploadData(data, url)
};

export const downloadPhoto = (filePath) => {
    let url='/service/media/download?filePath='+filePath;
    return downloadS3BucketFile(url).then(res => {
        return res;
    })
};

export const updatePhotoData = (data,advisoryId) => {
    let url='/service/advisory/document';
    return httpPost(url, data, advisoryId,true)
        .then((res) => {
            return res;
        })

};

export const updateAadharData = (data,advisoryId) => {
    return httpPut('/service/advisory/person/aadhaar', data, advisoryId,true)
        .then((res) => {
            return res;
        })
};

export const getGenderList = (advisoryId) => {
    return httpGet('/service/advisory/gender/all',advisoryId).then(res => {
        return res;
    })
};


//photo API End //

export const updatePanCardData = (data,advisoryId) => {
    return httpPut('/service/advisory/person/PAN', data, advisoryId,true)
        .then((res) => {
            return res;
        })
};


//upload document API start //
export const updateDocument = (data, fileType) => {
    if(fileType === 'application/pdf'){
        return uploadData(data, '/service/media/upload/pdf')
    }
    return uploadData(data, '/service/media/upload/image')
};


export const downloadFile = (filePath) => {
    let url='/service/media/download?filePath='+filePath;
    return downloadS3BucketFile(url).then(res => {
        return res;
    })
};

//upload document API sendtart //


//PAN Card API Start//
export const getPANCardData = (personId,advisoryId) => {

    return httpGet('/service/advisory/person/PAN?personId='+personId,advisoryId)
        .then(res => {
            return res;
        });

};

export const getDocAssociateData = (data,advisoryId) => {
    let url='/service/advisory/document';
    return httpPost(url, data, advisoryId,true)
        .then((res) => {
            return res;
        })
};

export const disAssociateDocument = (documentId,advisoryId) => {
    let url='/service/advisory/document?documentId='+documentId;
    return httpDelete(url,{},advisoryId, true)
        .then((res) => {
            return res;
        });
};

//PAN Card API End//
//onboarding details Start //
export const getOnboardingDetails = (onboardingApplicationId,advisoryId) => {

    return httpGet('/service/advisory/onboardingApplication?onboardingApplicationId='+onboardingApplicationId,advisoryId)
        .then(res => {
            return res;
        });

};

export const getPersonData = (personId,advisoryId) => {

    return httpGet('/service/advisory/person/profile?id='+personId,advisoryId,true)
        .then(res => {
            return res;
        });

};

export const getAadhaarData = (personId,advisoryId) => {
    return httpGet('/service/advisory/person/aadhaar?personId='+personId,advisoryId)
        .then(res => {
            return res;
        });
};

export const addNominee = (data , advisoryId) => {
    return httpPost('/service/advisory/account/nominee',data,advisoryId).then(res => {
        return res;
    })
};
export const updateNominee = (data , advisoryId) => {
    return httpPut('/service/advisory/account/nominee',data,advisoryId, true).then(res => {
        return res;
    })
};

export const removeNominee = (accountId , nomineeId, advisoryId) => {
    return httpDelete('/service/advisory/account/nominee?accountId='+accountId+'&nomineeId='+nomineeId,{},advisoryId).then(res => {
        return res;
    })
};

export const getNomineeListing = (accountId , advisoryId) => {
    return httpGet('/service/advisory/account/nominee/all?accountId='+accountId,advisoryId).then(res => {
        return res;
    })  //todo account is passing is pending
};

export const getRelationsList = (advisoryId) => {
    return httpGet('/service/advisory/nomineeRelation/all',advisoryId).then(res => {
        return res;
    })
};


//Kyc Status //
export const getKycDetails = (PANId,advisoryId) => {
    return httpGet('/service/advisory/person/kycStatus?PANNumber='+PANId,advisoryId, true)
        .then(res => {
            return res;
        });
};

export const refreshData = (data,advisoryId) => {
    return httpPost('/service/advisory/person/kyc',data,advisoryId)
        .then(res => {
            return res;
        });
};

export const getNomineeDetails = (nomineeId,accountId, advisoryId) => {
    return httpGet('/service/advisory/account/nominee?nomineeId='+nomineeId+'&accountId='+accountId,advisoryId)
        .then(res => {
            return res;
        })
};

export const getGuardianRelationsList = (advisoryId) => {
    return httpGet('/service/advisory/guardianRelation/all',advisoryId).then(res => {
        return res;
    })
};

export const updateGuardian = (data , advisoryId) => {
    return httpPut('/service/advisory/account/nominee/guardian',data,advisoryId, true).then(res => {
        return res;
    })
};

export const getCRMDetails = (advisoryId) => {
    return httpGet('/service/advisory/advisoryUser/all?role=CRM',advisoryId).then(res => {
        return res;
    })
};

export const getDocAssociateIINOrECSForm = (data,advisoryId) => {
    let url='/service/advisory/form/upload';
    return httpPost(url, data, advisoryId,true)
        .then((res) => {
            return res;
        })
};

export const updateNomineeOpted = (data , advisoryId) => {
    return httpPut('/service/advisory/account/nomineeEnabled',data,advisoryId, true)
        .then(res => {
        return res;
    })
};

export const getCRMs = (advisoryId) => {
    return httpGet('/service/advisory/advisoryUser/all?role=CRM',advisoryId).then(res => {
        return res;
    })
};

export const getLocations = (advisoryId) => {
    return httpGet('/service/advisory/location/all',advisoryId).then(res => {
        return res;
    })
};

export const assignCRMToLeads = (data , advisoryId) => {
    return httpPut('/service/advisory/lead/request/assign',data,advisoryId, true)
        .then(res => {
            return res;
        })
};

export const assignCRMToOnboarding = (data , advisoryId) => {
    return httpPut('/service/advisory/onboardingApplication/assign',data,advisoryId, true)
        .then(res => {
            return res;
        })
};

export const removeLead = (leadId, advisoryId) => {
    return httpDelete('/service/advisory/lead/request?id='+leadId,{},advisoryId).then(res => {
        return res;
    })
};

export const removeOnboarding = (onboardingId, advisoryId) => {
    return httpDelete('/service/advisory/onboardingApplication?id='+onboardingId,{},advisoryId, true).then(res => {
        return res;
    })
};

export const changeForceNewMandate = (data , advisoryId) => {
    return httpPut('/service/advisory/account/bankMandate/changeForceNewMandate',data,advisoryId, true)
        .then(res => {
            return res;
        })
};