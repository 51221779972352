import {isEmpty} from "../utils/validations";
import React from "react";
import appTheme from "../assets/appTheme";
import moment from "moment";

export const renderStatusBlock = (status, fontSize) => {
    if (isEmpty(status)) {
        return '-'
    }
    switch (status) {
        case 'NOT_CONTACTED':
            return (<div className="d-flex align-items-center">
                {renderStatusDot(status)}
                <span style={{fontSize: fontSize, color: appTheme.appColor.grayDark}}>Not contacted</span>
            </div>);
        case 'CONTACTED':
            return (<div className="d-flex align-items-center">
                {renderStatusDot(status)}
                <span style={{fontSize: fontSize, color: appTheme.appColor.grayDark}}>Contacted</span>
            </div>);

        case 'ON_HOLD':
            return (<div className="d-flex align-items-center">
                <i className="fa-solid fa-pause me-2" style={{color: appTheme.appColor.grayDark}}/>
                <span style={{fontSize: fontSize, color: appTheme.appColor.grayDark}}>On hold</span>
            </div>);
        case 'LOST':
            return (<div className="d-flex align-items-center">
                <i className="fa-solid fa-xmark me-2 " style={{color: appTheme.appColor.danger}}/>
                <span style={{fontSize: fontSize, color: appTheme.appColor.grayDark}}>Lost</span>
            </div>);
        case "ONBOARDING_INITIATED" :
            return (<div className="d-flex align-items-center">
                {renderStatusDot(status)}
                <span style={{fontSize: fontSize, color: appTheme.appColor.grayDark}}>Onboarding initiated</span>
            </div>);

            case "ONBOARDING_COMPLETED" :
            return (<div className="d-flex align-items-center">
                <i className="fa-solid fa-check me-2 " style={{color: appTheme.appColor.success}}/>
                <span style={{fontSize: fontSize, color: appTheme.appColor.grayDark}}>Onboarding completed</span>
            </div>);
        default: return '';
    }
};

export const renderLeadDetailsStatusBadge = (status) => {
    if (isEmpty(status)) {
        return '-'
    }
    switch (status) {
        case 'NOT_CONTACTED':
            return (<div className="d-flex align-items-center badge-amber ms-0">
                <span style={{color: appTheme.appColor.white}}>Not contacted</span>
            </div>);
        case 'CONTACTED':
            return (<div className="d-flex align-items-center badge-blue ms-0">
                <span style={{color: appTheme.appColor.white}}>Contacted</span>
            </div>);
        case 'ON_HOLD':
            return (<div className="d-flex align-items-center badge-grayDark ms-0">
                <span style={{color: appTheme.appColor.white}}>On hold</span>
                <i className="fa-solid fa-pause ms-2" style={{color: appTheme.appColor.white}}/>
            </div>);
        case 'LOST':
            return (<div className="d-flex align-items-center badge-red ms-0">
                <span style={{color: appTheme.appColor.white}}>Lost</span>
                <i className="fa-solid fa-xmark ms-2 " style={{color: appTheme.appColor.white}}/>
            </div>);
        case 'ONBOARDING_INITIATED':
            return (<div className="d-flex align-items-center badge-green ms-0">
                <span style={{color: appTheme.appColor.white}}>Onboarding initiated</span>
                <i className="fa-solid fa-check ms-2 " style={{color: appTheme.appColor.white}}/>
            </div>);
            case 'ONBOARDING_COMPLETED':
            return (<div className="d-flex align-items-center badge-green ms-0">
                <span style={{color: appTheme.appColor.white}}>Onboarding completed</span>
                <i className="fa-solid fa-check ms-2 " style={{color: appTheme.appColor.white}}/>
            </div>);
        default: return '';
    }
};

export const renderStatusDot = (status) => {
    if (isEmpty(status)) {
        return '-'
    }
    switch (status) {
        case 'NOT_CONTACTED':
            return <span className="status-dot warning"/>;

        case 'CONTACTED':
            return <span className="status-dot primary"/>;

        case 'ON_HOLD':
            return <span className="status-dot primary "/>;

        case 'LOST':
            return <span className="status-dot danger"/>;

        case 'ONBOARDING_COMPLETED':
            return <span className="status-dot success"/>;

        case 'ONBOARDING_INITIATED':
            return <span className="status-dot success"/>;
    }
};

export const renderStatusDotWithDays = (date) => {

    let diff = moment().diff(moment(date), 'days')

    if (isEmpty(date)) {
        return '-'
    }
    if(diff <= 7){
        return(
            <div>
                <span className="status-dot yellow"/>
                <span style={{fontSize: "12px", color: appTheme.appColor.grayDark}}> 0-7 Days </span>
            </div>
        )
    }else if(diff > 7 && diff <= 15){
        return(
            <div>
                <span className="status-dot warning"/>
                <span style={{fontSize: "12px", color: appTheme.appColor.grayDark}}> 7-15 Days </span>
            </div>
        )
    }else if(diff > 15){
        return(
            <div>
                <span className="status-dot danger"/>
                <span style={{fontSize: "12px", color: appTheme.appColor.grayDark}}> > 15 Days </span>
            </div>
        )
    }
};


export const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
        event.preventDefault();
        document.activeElement?.blur();
    }
};
