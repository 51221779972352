import React from 'react';
import DLText from "../../../core/components/DLText/DLText";
import {isEmpty} from "../../../utils/validations"
import DLLoader from "../../../core/components/DLLoader/DLLoader";
import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext/TabContext";
import appTheme from "../../../assets/appTheme";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import DocumentUpload from "../documents/DocumentUpload";
import PersonalProfile from "../personalProfile/PersonalProfile";
import FinancialProfile from "../financialProfile/FinancialProfile";
import BankAccount from "../bankAccount/BankAccount";
import NomineeList from "../nominee/NomineeList";
import FormAndReview from "../formsAndReview/FormsAndReview";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu/Menu";
import {getOnboardingStatus} from "../../OnboardingHelper";
import {onboardingStatuses} from "../../OnboardingConsts";
import DLMenuOptions from "../../../core/components/DLMenuOptions/DLMenuOptions";
import ReassignCRM from "../reassignCRM/ReassignCRM";
import DeleteOnboarding from "../deleteOnboarding/DeleteOnboarding";


const OnboardingDetailsView = (props) => {
    const {
        isMobileView, isShowLoader, selectedTab, handleTabChange, isFromOnboardingFlow, isShowApplicantCounts,
        selectDocument, onboardingApplicationId, applicantDetails, saveFailureErrorMessage, getFilledApplicantsDetails, handleItemOpen, anchorEl, handleItemClose
    } = props;

    const renderLoader = () => {
        return (<DLLoader type={"screen"} isVisible={isShowLoader}/>);
    };

    const renderApplicantDetails = (applicantDetails) => {
        return applicantDetails.map((applicant, index) => {
            return (
                <div key={'applicants' + index}
                     className={(applicantDetails?.length === index + 1) ? 'mb-1' : 'border-bottom mb-1 pb-2'}>
                    <DLText id={'lead-name'}
                            text={'Applicant ' + (index + 1) + ': ' + applicant?.name}
                            marginBottom={"none"}
                            fontSize={"xs"}
                    />
                    <div>
                        <a href={'mailTo: ' + applicant?.email}
                           style={{fontSize: 'var(--font-size-xs)', fontFamily: appTheme.default.fontFamily}}>
                            {applicant?.email}
                        </a>
                    </div>
                    <div>
                        <a href={'tel: ' + applicant?.phone}
                           style={{fontSize: 'var(--font-size-xs)', fontFamily: appTheme.default.fontFamily}}>
                            {applicant?.phone}
                        </a>
                    </div>
                </div>
            );
        });

    };

    const renderApplicantsUI = () => {

        let allApplicantsNames = "";
        applicantDetails.map((person) => {
            if (isEmpty(allApplicantsNames)) {
                allApplicantsNames = person?.name
            } else {
                allApplicantsNames = allApplicantsNames + ', ' + person?.name;
            }
        });

        return (
            <div key={'person'}
                 className='d-flex align-items-center '>
                <div>
                    {(!isMobileView) &&
                    <div className='pe-3' onClick={(event) => {
                        handleItemOpen(event)
                    }}>
                        <DLText id={'lead-name'}
                                text={allApplicantsNames}
                                marginBottom={"none"}
                                isClickable={true}
                                fontColor={"darkBlue"}
                        />
                    </div>
                    }
                    <Menu id="items-menu"
                          anchorEl={anchorEl}
                          getcontentanchorel={null}
                          anchorOrigin={{vertical: "bottom", horizontal: "right"}}
                          transformOrigin={{vertical: "top", horizontal: "right"}}
                          open={Boolean(anchorEl)}
                          onClose={() => handleItemClose()}
                          className="p-2 w-100"
                          disableAutoFocusItem
                          elevation={0}
                          PaperProps={{
                              style: {
                                  marginTop: '10px',
                                  boxShadow: '0 4px 4px rgba(147, 147, 147, 0.25)'
                              }
                          }}
                          MenuListProps={{
                              style: {
                                  color: appTheme.appColor.black,
                                  maxHeight: '400px',
                                  textAlign: 'center'
                              },
                          }}>
                        <MenuItem key={'menuItem-'}
                                  className={'py-0 text-center'}
                                  style={{minWidth: 200, height: "max-content", cursor: "auto"}} disableRipple>
                            <div className='text-start'>
                                {renderApplicantDetails(applicantDetails)}
                            </div>
                        </MenuItem>
                    </Menu>
                </div>
            </div>
        );
    };

    const renderApplicantsDetails = () => {
        if (isEmpty(applicantDetails)) {
            return ''
        }
        let applicantsWithDetailsUploaded = getFilledApplicantsDetails(applicantDetails);
        if (applicantsWithDetailsUploaded?.length === applicantDetails?.length) {
            return renderApplicantsUI()

        } else if (!isEmpty(applicantsWithDetailsUploaded)) {
            let filledApplicantDetails = applicantsWithDetailsUploaded[0];

            if (!isEmpty(filledApplicantDetails)) {
                return (
                    <div>
                        <DLText id={'lead-name'}
                                text={filledApplicantDetails?.name + ' + ' + (applicantDetails?.length - 1)}
                                marginBottom={"none"}
                                isClickable={false}
                        />
                    </div>
                )
            }
        } else {
            let totalApplicants = (applicantDetails?.length === 1) ? '1 Applicant' : applicantDetails?.length + ' Applicants';
            if (isMobileView) {
                return;
            }
            return (
                <DLText id={''}
                        text={totalApplicants}
                        marginBottom={"none"}
                        fontSize={"md"}
                        fontWeight={"semi-bold"}
                        fontColor={"grayDark"}
                />
            )
        }
    };

    const renderReassignCRMModal = () => {
        const {isMobileView, handleCloseReassignCRMModal, isReassignCRMModalOpen} = props;

        if (isReassignCRMModalOpen) {
            if (isMobileView) {
                return (<div className="modal-overlay" style={{zIndex: 1100}} onClick={handleCloseReassignCRMModal}>
                    <div className="modal-content box-shadow " onClick={(e) => e.stopPropagation()}>
                        <ReassignCRM {...props} isOverlay={true}/>
                    </div>
                </div>)
            } else {
                return (
                    <ReassignCRM {...props}/>
                )
            }
        }

    };

    const renderDeleteOnboardingModal = () => {
        const {isMobileView, handleCloseDeleteOnboardingModal, isDeleteOnboardingModalOpen} = props;

        if (isDeleteOnboardingModalOpen) {
            if (isMobileView) {
                return (
                    <div className="modal-overlay" style={{zIndex: 1100}} onClick={handleCloseDeleteOnboardingModal}>
                        <div className="modal-content box-shadow " onClick={(e) => e.stopPropagation()}>
                            <DeleteOnboarding {...props} isOverlay={true}/>
                        </div>
                    </div>)
            } else {
                return (
                    <DeleteOnboarding {...props}/>
                )
            }
        }

    };

    const renderMenuOptions = () => {
        const {selectedOption} = props;
        return (
            <div className="d-flex justify-content-around">
                <div className="px-3" style={{fontSize : '16px'}}>
                    <DLMenuOptions
                        id={"menuOption"}
                        onSelect={(value) =>
                            selectedOption(value)
                        }
                        options={[{
                            label: "Reassign",
                            value: "REASSIGN"
                        },
                            {
                                label: "Remove",
                                value: "REMOVE"
                            }]}
                    />
                </div>
            </div>
        )
    }

    const renderOnboradingStatus = () => {
        const {onboardingStatus} = props;

        let status = getOnboardingStatus(onboardingStatus, onboardingStatuses);
        if (status !== '-' && !isEmpty(status)) {
            if (status === 'Verified' || status === 'Activated') {
                return <span style={{maxWidth: 'max-content'}} className={'badge-green my-1 mx-0'}>{status}</span>
            } else {
                return <span style={{maxWidth: 'max-content'}} className={'badge-amber my-1 mx-0'}>{status}</span>
            }
        }
    };

    const renderDesktopHeader = () => {
        const {accountType, isXSView, onboardingStatus, isManagementUser} = props;
        return (
            <div className='w-100'>
                <div className='page-header'>
                    <div className={isXSView ? '' : 'd-flex justify-content-between'}>
                        <div className={"d-flex align-items-end"}>
                            <div className='d-flex align-items-center'>
                                <i className={'fa-solid fa-chevron-left cursor fa-2xs me-2 ' + (isMobileView ? ' pt-1 ' : '')}
                                   style={{width: 10}}
                                   onClick={() => {
                                       props.history.goBack()
                                   }}
                                />
                                {!isMobileView ?
                                    <div>
                                        Onboarding
                                    </div>
                                    :
                                    <DLText id={''}
                                            text={'Onboarding'}
                                            fontWeight={"semi-bold"}
                                            fontSize={"lg"}
                                            marginBottom={"none"}
                                    />
                                }
                            </div>
                            {!isEmpty(accountType) &&
                            <div className='ps-1'>
                                <DLText id={''}
                                        text={isMobileView ? (accountType === 'JOINT') ? 'Joint A/C' : 'Individual A/C' : (accountType === 'JOINT') ? 'Joint Account' : 'Individual Account'}
                                        fontColor={"grayDark"}
                                        fontWeight={"semi-bold"}
                                        fontSize={isMobileView ? 'sm' : "md"}
                                        marginBottom={"none"}
                                />
                            </div>}
                            {(!isEmpty(applicantDetails)) && (!isEmpty(accountType)) &&
                            <div className='d-flex align-items-center ps-1'>
                                {!isMobileView ? <div>
                                        <div className='px-1'>
                                            <DLText id={''}
                                                    text={'-'}
                                                    marginBottom={"none"}
                                            />
                                        </div>
                                    </div>
                                    :
                                    <div onClick={(event) => {
                                        handleItemOpen(event)
                                    }}>
                                        <div className='px-1'>
                                            <DLText id={''}
                                                    isClickable={(!isEmpty(getFilledApplicantsDetails(applicantDetails)))}
                                                    text={'(' + applicantDetails.length + ')'}
                                                    marginBottom={"none"}
                                            />
                                        </div>
                                    </div>
                                }
                                {renderApplicantsDetails()}
                            </div>}
                        </div>
                        <div className='d-flex align-items-center'>
                            {renderOnboradingStatus()}
                            {/*{isManagementUser() && renderMenuOptions()}*/}
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    const renderAllTabs = () => {
        const {allTabs, selectedTab} = props;
        return allTabs?.map((item, index) => {
            return (
                <Tab key={'tab_' + index}
                     label={item.label}
                     onClick={() => {
                         handleTabChange('selectedTab', {
                             label: item.label,
                             value: item.value
                         })
                     }}
                     style={{
                         textTransform: 'none',
                         color: appTheme.appColor.black,
                         fontFamily: appTheme.default.fontFamily,
                         fontWeight: (item?.value === selectedTab?.value) ? 500 : 400
                     }}
                     value={item.value}/>

            );
        })
    };

    const renderDesktopDetails = () => {
        return (
            <div>
                <div className="page-container">
                    <Box sx={{width: '100%'}}>
                        <TabContext value={selectedTab?.value}>
                            <Box sx={{borderBottom: 2, borderColor: appTheme.appColor.grayLight}}>
                                <TabList onChange={() => {
                                }} aria-label="lab API tabs-example"
                                         variant="scrollable"
                                         scrollButtons={false}>
                                    {renderAllTabs()}
                                </TabList>
                            </Box>
                        </TabContext>
                    </Box>
                </div>
                <div className={isMobileView ? '' : 'bg-light'}>
                    {renderTabContentUI()}
                </div>
            </div>
        )
    };

    const renderTabContentUI = () => {
        const {
            selectedTab, selectedScreenObj, screenDetails, getUplodedDocuments, handleClickNext,
            totalApplicants, changePersonTab, accountHoldersPAN, handleChangeOnboardingStatus, onboardingStatus
        } = props;
        switch (selectedTab?.value) {
            case 'DOCUMENTS':
                if (isEmpty(screenDetails)) {
                    return
                } else {
                    return <DocumentUpload
                        history={props.history}
                        screenDetails={screenDetails}
                        selectedScreenObj={selectedScreenObj}
                        isProceedWithErrors={false}
                        subScreenKey={selectedScreenObj?.screenKey}
                        isFromOnboardingFlow={isFromOnboardingFlow}
                        getUplodedDocuments={getUplodedDocuments}
                        handleClickNext={handleClickNext}
                        totalApplicants={totalApplicants}
                        selectDocument={(value, id) => {
                            selectDocument(value, id)
                        }}
                        saveFailureErrorMessage={saveFailureErrorMessage}

                    />;
                }
            case 'PERSONAL_PROFILE':
                return <PersonalProfile
                    handleClickNext={handleClickNext}
                    history={props.history}
                    selectedScreenObj={selectedScreenObj}
                    isProceedWithErrors={false}
                    changePersonTab={changePersonTab}
                    totalApplicants={totalApplicants}
                    isFromOnboardingFlow={isFromOnboardingFlow}
                    saveFailureErrorMessage={saveFailureErrorMessage}
                />;
            case 'FINANCIAL_PROFILE':
                return <FinancialProfile
                    history={props.history}
                    handleClickNext={handleClickNext}
                    selectedScreenObj={selectedScreenObj}
                    isProceedWithErrors={false}
                    saveFailureErrorMessage={saveFailureErrorMessage}
                    isFromOnboardingFlow={isFromOnboardingFlow}
                />;
            case 'BANK_ACCOUNT':
                return <BankAccount
                    history={props.history}
                    handleClickNext={handleClickNext}
                    selectedScreenObj={selectedScreenObj}
                    isProceedWithErrors={false}
                    isFromOnboardingFlow={isFromOnboardingFlow}
                />;
            case 'NOMINEE':
                return <NomineeList
                    history={props.history}
                    handleClickNext={handleClickNext}
                    selectedScreenObj={selectedScreenObj}
                    onboardingApplicationId={onboardingApplicationId}
                    isProceedWithErrors={false}
                    isFromOnboardingFlow={isFromOnboardingFlow}
                    saveFailureErrorMessage={saveFailureErrorMessage}
                />;
            case 'FORMS_REVIEW':
                return <FormAndReview
                    history={props.history}
                    handleClickNext={handleClickNext}
                    selectedScreenObj={selectedScreenObj}
                    isProceedWithErrors={false}
                    onboardingApplicationId={onboardingApplicationId}
                    isFromOnboardingFlow={isFromOnboardingFlow}
                    accountHoldersPAN={accountHoldersPAN}
                    saveFailureErrorMessage={saveFailureErrorMessage}
                    selectedTab={selectedTab}
                    onboardingStatus={onboardingStatus}
                    handleChangeOnboardingStatus={handleChangeOnboardingStatus}
                />
        }
    };

    const renderErrorUI = () => {
        const {responseErrors, totalErrors} = props;

        if (isEmpty(responseErrors) || totalErrors === 0) {
            return '';
        }

        return (
            <div id='page-level-errors'
                 className={isMobileView ? '' : 'mb-2'}>
                {responseErrors.slice(0, 3).map((errorMessage, index) => (
                    <div key={index} className='pb-2 align-items-center d-flex message-block'>
                        <div className='error-vertical-line'/>
                        <div className='error-panel'>
                            {errorMessage}
                        </div>
                    </div>
                ))}
                {totalErrors > 3 && (
                    <div className='py-2 align-items-center d-flex'>
                        {`...${totalErrors - 3} More`}
                    </div>
                )}
            </div>
        );
    };

    const renderDesktopTabsUI = () => {
        return (
            <div className="">
                <div style={{flex: 0.7}}>
                    {renderDesktopDetails()}
                </div>
            </div>)
    };

    const renderDesktopUI = () => {
        return (
            <div>
                {renderDesktopHeader()}
                <div>
                    {renderErrorUI()}
                    {renderDesktopTabsUI()}
                </div>
            </div>
        );
    };

    const renderMobileHeader = () => {
        return (
            <div className='bg-light'>
                {renderDesktopHeader()}
                <div className='page-container bg-white'>
                    {renderDesktopTabsUI()}
                </div>
            </div>
        );
    };

    const renderMobileUI = () => {
        return (
            <div>
                {renderMobileHeader()}
            </div>
        );
    };

    return (
        <div>
            {isMobileView ? renderMobileUI() : renderDesktopUI()}
            {renderReassignCRMModal()}
            {renderDeleteOnboardingModal()}
            {renderLoader()}
        </div>
    );
};

export default OnboardingDetailsView;