export const headingData = ['Client Name (Category)', 'Ticket Number', 'Type of Request', 'Open Date', 'Status'];

export const cellConfig = [
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '200px'
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '200px'
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '200px'
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '200px'
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '200px'
    }
];


export const typeOfRequestFilterOptions = [
    {label: 'All', value: 'ALL'},
    {label: 'Nominee Change', value: 'NOMINEE_CHANGE'},
    {label: 'Fund Transfer after death', value: 'FUND_TRANSFER_AFTER_DEATH'},
    {label: 'Change in Email/Phone Number', value: 'EMAIL_OR_PHONE_NO_CHANGE'},
    {label: 'ARN CHANGE', value: 'ARN_CHANGE'},
    {label: 'KYC Change', value: 'KYC_CHANGE'},
    {label: 'Change of Bank', value: 'BANK_CHANGE'}
];

export const statusFilterOptions = [
    {label: 'All', value: 'ALL'},
    {label: 'In Progress', value: 'IN_PROGRESS'},
    {label: 'Completed', value: 'COMPLETED'},
    {label: 'Discarded', value: 'DISCARDED'},
    {label: 'Open', value: 'OPEN'}
];