import React, {useEffect} from 'react';
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";

import {getTimeFromNow, getTrimmedValue} from "../../../../../utils/helper";
import {isEmpty} from "../../../../../utils/validations";
import appTheme from "../../../../../assets/appTheme";

import DLLoader from "../../../../../core/components/DLLoader/DLLoader";
import DLText from "../../../../../core/components/DLText/DLText";
import DLCheckbox from "../../../../../core/components/DLCheckbox/DLCheckbox";
import DLMenuOptions from "../../../../../core/components/DLMenuOptions/DLMenuOptions";

import Reviewed from "./reviewed/reviewed";
import ViewActivity from "./viewActivity/ViewActivities";
import IINActivity from "./IINActivity/IINActivity";
import UploadIINForm from "./upload/UploadIINForm";
import AddRemark from "./addRemark/AddRemark";
import DLTooltip from "../../../../../core/components/DLTooltip/DLTooltip";
import {getOnboardingStatus} from "../../../../OnboardingHelper";
import {onboardingStatuses} from "../../../../OnboardingConsts";
import DLButton from "../../../../../core/components/DLButton/DLButton";

const IINFormView = (props) => {
    const {
        isShowLoader, setElectronicConsent, electronicConsent, regenerateForm, handleOpenMarkAsReviewCheckBox, onboardingStatus,setActivityStatus,
        fileName, filePath, isReviewed, isMobileView, selectedOption, setIINForm, downloadAndViewDocument, isIINSubmitted, submitIIN, resetIINSubmissionStatus,
        generatedFormDetails, isManagementUser, formId, responseErrors
    } = props;

    useEffect(() => {
        if (!isEmpty(responseErrors)) {
            const element = document.getElementById('page-level-errors-iin');
            if (!isEmpty(element)) {
                element.scrollIntoView({behavior: 'smooth', block: 'center'});
            }
        }
    }, [props.responseErrors]);

    const renderMarkAsReviewed = () => {
        return (
            <div className={"me-2"}>
                <div className='data-label'
                     style={{minWidth: '50px'}}>
                    <DLText id={'kyc-Summary'}
                            fontSize={'xs'}
                            fontColor={(!isReviewed && !isManagementUser) ? "primary" : "gray"}
                            text={'Reviewed'}/>
                </div>
                <div className='d-flex align-items-center'>
                    <DLCheckbox
                        id={'mark-as-reviewed-dayCheck'}
                        label={''}
                        isChecked={isReviewed}
                        disableHelperMessageHeight={true}
                        onChangeValue={() => {
                            handleOpenMarkAsReviewCheckBox()
                        }}
                        labelPlacement={'top'}
                        fontSize={"sm"}
                        marginBottom={'none'}
                        isDisabled={isReviewed || isManagementUser}
                        checkboxcolor={(!isReviewed && !isManagementUser) ? appTheme.appColor.black : appTheme.appColor.gray}
                    />
                </div>
            </div>

        )
    };

    const renderSwitch = () => {
        const{onboardingStatus} = props;
        let isDisabled =  (onboardingStatus === 'ACTIVATED' || onboardingStatus === 'AWAITING_CONFIRMATION' || onboardingStatus === 'CONFIRMED' || onboardingStatus === 'SUBMISSION_IN_PROGRESS' || isManagementUser );
        return (
            <div>
                <div className="d-flex justify-content start align-items-center pb-3">
                    <div className={"me-2"}>
                        <DLText id={'kyc-Summary'}
                                fontSize={'xs'}
                                fontColor={"primary"}
                                text={'Electronic Consent'}/>
                    </div>
                    <ButtonGroup style={{
                        textTransform: 'none',
                        maxHeight: '30px',
                        borderRadius: '25px',
                        backgroundColor: '#E8EEF8'
                    }}
                                 variant="outlined"
                                 className='btn-group '
                                 aria-label=" button group">
                        <Button id={'major-toggle'}
                                className={electronicConsent ? " bg-white m-1 py-1" : "py-1"}
                                style={{
                                    textTransform: 'none',
                                    border: 'none',
                                    fontFamily: appTheme.default.fontFamily,
                                    fontWeight: electronicConsent ? 500 : 400,
                                    color: isDisabled ? appTheme.appColor.disable : electronicConsent ? 'var(--primary)' : 'var(--gray-dark)',
                                    borderRadius: '25px',
                                    fontSize: 14,
                                    cursor: isDisabled? 'auto': 'pointer'
                                }}
                                onClick={() => {
                                    if(!isDisabled){
                                        setElectronicConsent(true)
                                    }
                                }}
                        >
                            Yes
                        </Button>
                        <Button id={'minor-toggle'}
                                className={!electronicConsent ? " bg-white m-1 py-1" : "py-1"}
                                style={{
                                    textTransform: 'none',
                                    border: 'none',
                                    fontFamily: appTheme.default.fontFamily,
                                    fontWeight: !electronicConsent ? 500 : 400,
                                    color: isDisabled ? appTheme.appColor.disable : !electronicConsent ? 'var(--primary)' : 'var(--gray-dark)',
                                    borderRadius: '25px',
                                    fontSize: 14,
                                    cursor: isDisabled? 'auto': 'pointer'
                                }}
                                onClick={() => {
                                    if(!isDisabled){
                                        setElectronicConsent(false)
                                    }

                                }}
                        >
                            No
                        </Button>
                    </ButtonGroup>

                </div>
                {electronicConsent ?
                    <div className={"me-2"}>
                        <DLText id={''}
                                text={"Investor will electronically sign the form generated through the portal."}
                                fontSize={'sm'}
                                fontWeight={"normal"}
                                marginBottom={'none'}
                        />
                    </div>
                    :
                    <div className={'me-2 ' + (isMobileView ? '' : 'mb-2')}>
                        <DLText id={''}
                                text={"In this mode please upload the form with investor signature"}
                                fontSize={'sm'}
                                fontWeight={"normal"}
                                marginBottom={'md'}
                        />
                        <DLButton id={'delete-button'}
                                  buttonSize={'sm'}
                                  fontSize={'sm'}
                                  sideMargin={'none'}
                                  styleType={"outlined"}
                                  isDisabled={isManagementUser || isDisabled}
                                  onClick={() => {
                                      selectedOption('Upload')
                                  }}
                                  label={"Upload Signed Form"}/>
                    </div>
                }
            </div>
        )
    };

    const renderLatestActivityTime = () => {
        const {generatedTime, uploadedTime} = props;
        let generatedDate = new Date(generatedTime);
        let uploadedDate = new Date(uploadedTime);
        if (generatedDate > uploadedDate || (!isEmpty(generatedDate) && isEmpty(uploadedDate))) {
            let formatedGeneratedTime = getTimeFromNow(generatedTime, 'DD-MM-YYYY');
            return (
                <div>
                    <DLText id={''}
                            text={"Generated "}
                            fontSize={'xs'}
                            fontWeight={"normal"}
                            isInline={true}
                            marginBottom={'none'}
                    />
                    <DLText id={''}
                            text={formatedGeneratedTime}
                            fontSize={'xs'}
                            fontWeight={"normal"}
                            fontColor={"disable"}
                            isInline={true}
                            marginBottom={'none'}
                    />
                </div>
            )
        }

        if (generatedDate < uploadedDate || (isEmpty(generatedDate) && !isEmpty(uploadedDate))) {
            let formatedUploadedTime = getTimeFromNow(uploadedTime, 'DD-MM-YYYY');
            return (
                <div>
                    <DLText id={''}
                            text={"Uploaded "}
                            fontSize={'xs'}
                            fontWeight={"normal"}
                            isInline={true}
                            marginBottom={'none'}
                    />
                    <DLText id={''}
                            text={formatedUploadedTime}
                            fontSize={'xs'}
                            fontWeight={"normal"}
                            fontColor={"disable"}
                            isInline={true}
                            marginBottom={'none'}
                    />
                </div>
            )
        }
        return ''
    };

    const renderFormPdf = () => {
        return (
            <div className={"me-2"}>
                <div className='data-label'
                     style={{minWidth: '50px'}}>
                    <DLText id={'kyc-Summary'}
                            fontSize={'xs'}
                            fontColor={'grayDark'}
                            text={'IIN Form'}/>
                </div>
                <div className='d-flex align-items-center'>
                    <DLText id={''}
                            text={getTrimmedValue(fileName, 12)}
                            fontSize={'sm'}
                            fontWeight={"semi-bold"}
                            isClickable={!isEmpty(isEmpty(filePath))}
                            onClick={() => {
                                downloadAndViewDocument('View')
                            }}
                            fontColor={isEmpty(filePath) ? "black" : "darkBlue"}
                            marginBottom={'none'}
                    />
                    {renderButtons()}
                </div>
                {renderLatestActivityTime()}
            </div>
        )
    };

    const renderButtons = () => {
        return (
            <div className="d-flex justify-content-center align-items-center">
                <div className="ms-3">
                    <div>
                        <i className={"fa-solid fa-download " + (isEmpty(filePath) ? ' disable-icon cursor' : ' default-icon cursor')}
                           onClick={() => {
                               if (!isEmpty(filePath)) {
                                   downloadAndViewDocument('Download')
                               }
                           }}/>
                    </div>
                </div>

                <div className="me-2 ps-3">
                    <DLTooltip tooltipPlacement={'right'}
                               tooltipLabel={
                                   <div onClick={() => {
                                       if(!isManagementUser){
                                           regenerateForm()
                                       }
                                   }}>
                                       <i className={"fa-solid fa-rotate" + (isManagementUser ? ' disable-icon' : ' default-icon cursor')}/>
                                   </div>
                               }
                               id={''}
                               customUi={true}
                               tooltipContent={<DLText id={''}
                                                       fontSize={'xs'}
                                                       text={'Regenerate'}
                                                       marginBottom={'none'}/>}
                    />
                </div>
            </div>
        )
    };

    const renderUploadModal = () => {
        const {isUploadIINFormModalOpen} = props;

        if (isUploadIINFormModalOpen) {
            return (
                <UploadIINForm {...props}/>
            )
        }

    };

    const renderMarkAsReviewedModal = () => {
        const {isMarkAsReviewedModalOpen} = props;

        if (isMarkAsReviewedModalOpen) {
            return (
                <Reviewed {...props} />
            )
        }

    };

    const renderAddRemarkModal = () => {
        const {isAddRemarkModalOpen} = props;

        if (isAddRemarkModalOpen) {
            return (
                <AddRemark {...props}/>
            )
        }
    };

    const renderOnboradingStatus = () =>{
        const {onboardingStatus} = props;

        let status = getOnboardingStatus(onboardingStatus, onboardingStatuses);
        if(status !== '-' && !isEmpty(status)){
            if(status === 'Verified' || status === 'Activated'){
                return <span style={{maxWidth: 'max-content'}} className={'badge-green mx-0'}>{status}</span>
            }else{
                return <span style={{maxWidth: 'max-content'}} className={'badge-amber mx-0'}>{status}</span>
            }
        }
    };

    const renderMenuOptions = (electronicConsent, isManagementUser) => {
        if(isManagementUser){
            return [{label: "View Activities", value: "View Activities"}]
        }
        if(electronicConsent){
            return [{label: "Add a Remark", value: "Add a Remark"},
                {label: "View Activities", value: "View Activities"}]
        }
        return [{label: "Add a Remark", value: "Add a Remark"},
            {label: "View Activities", value: "View Activities"}]
    };
    const renderHeader = () => {
        const {IINNumber} = props;
        return (
            <div>
                <div className={'d-flex justify-content-between align-items-center  ' + (isMobileView ? 'py-3' : 'px-3 pt-3')}>
                    <div className='d-flex align-items-end' style={{gap: 4}}>
                        <DLText id={'iin-form-id'}
                                text={"IIN"}
                                fontSize={"lg"}
                                marginBottom={"none"}
                                fontWeight={"semi-bold"}
                        />
                        {!isEmpty(IINNumber) && <DLText id={'iin-form-id'}
                                                        marginBottom={"xs"}
                                                        text={"(" + IINNumber + ")"}
                                                        fontSize={"sm"}
                                                        fontColor={"grayDark"}
                                                        fontWeight={"bold"}
                        />}
                    </div>
                    <div className='d-flex align-items-center'>
                        <div className={isMobileView ? 'px-2':''}>
                        {!isEmpty(formId) && <DLMenuOptions
                            onSelect={(value) => selectedOption(value)}
                            options={renderMenuOptions(electronicConsent, isManagementUser)}
                            id={"IINFormAction"}
                        />}
                        </div>
                    </div>
                </div>
                <div className={' pb-3 ' + (isMobileView ? '' : 'px-3 border-bottom')}>
                    {renderOnboradingStatus()}
                </div>
            </div>
        )
    };

    const renderViewActivity = () => {
        const {isMobileView, handleCloseViewActivitiesModal, isViewActivitiesModalOpen} = props;
        if (isViewActivitiesModalOpen) {
            if (isMobileView) {
                return <div className="modal-overlay" style={{zIndex: 1100}} onClick={handleCloseViewActivitiesModal}>
                    <div className="modal-content box-shadow " onClick={(e) => e.stopPropagation()}>
                        <ViewActivity {...props} isOverlay={true}/>
                    </div>
                </div>
            } else {
                return (
                    <ViewActivity {...props}/>
                )
            }
        }
    };

    const renderLoader = () => {
        return (<DLLoader type={"screen"} isVisible={isShowLoader}/>);
    };

    if (props.UIState === 'CONTENT_UNAVAILABLE' || isEmpty(props.UIState)) {
        return ''
    }

    const renderErrorUI = () => {
        const {responseErrors, isMobileView} = props;

        if (isEmpty(responseErrors)) {
            return '';
        }

        return (
            <div id='page-level-errors-iin'
                 className={isMobileView ? '' : 'mb-2'}>
                <div className='pb-2 align-items-center d-flex message-block'>
                    <div className='error-vertical-line'/>
                    <div className='error-panel'>
                        {responseErrors}
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className={isMobileView ? "mx-0" : "mx-0 border border-rounded bg-white"}>
            {renderHeader()}
            <div className={isMobileView ? '' : 'panel-container'}
                 style={{
                     maxHeight: isMobileView ? 'auto':'calc(100dvh - 403px)',
                     minHeight: isMobileView ? 'calc(100dvh - 405px)': 'calc(100dvh - 306px)',
                     overflow: "auto"}}>
                {renderErrorUI()}
                {(!isEmpty(fileName)) ? <div className="w-100">
                        <div className="d-flex align-items-start justify-content-between pb-3">
                            <div className=''>
                                {renderFormPdf()}
                            </div>
                            <div className=''>
                                {renderMarkAsReviewed()}
                            </div>
                        </div>
                        <div className='pb-3 pt-3 border-top'>
                            {renderSwitch()}
                        </div>
                    </div> :
                    <div className='pb-4 pt-2'>
                        <DLButton id={'lost-button'}
                                  type={"black"}
                                  styleType={"outlined"}
                                  onClick={() => {
                                      regenerateForm()
                                  }}
                                  isDisabled={isManagementUser}
                                  sideMargin={'none'}
                                  padding={'none'}
                                  label={'Generate Form'}
                        />
                    </div>}

                <div>
                    <IINActivity {...props}
                                 setActivityStatus={setActivityStatus}
                                 resetIINSubmissionStatus={resetIINSubmissionStatus}
                                 isIINSubmitted={isIINSubmitted}
                                 submitIIN={submitIIN}
                                 setIINForm={(value) => {
                                     setIINForm(value)
                                 }}/>
                </div>

                {renderLoader()}
                {renderUploadModal()}
                {renderAddRemarkModal()}
                {renderMarkAsReviewedModal()}
                {renderViewActivity()}
            </div>
        </div>);
};


export default IINFormView;