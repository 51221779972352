import React, {} from 'react';
import {connect} from "react-redux";

import NFTListForCRM from "./NFTListForCRM/NFTListForCRM";


const NFTListView = (props) => {
        const {isSetUserType} = props;
        if (!isSetUserType) {
            return ''
        }

        return (
            <div className='w-100'>
                <NFTListForCRM {...props} />
            </div>
        );
    }
;

const mapStateToProps = (state) => ({
    profile: state.userState.profile
});

export default connect(mapStateToProps, {})(NFTListView);