import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";

import {getTimeFromNow} from "../../../../../utils/helper";
import {isEmpty} from "../../../../../core/components/DLComponentHelper";
import {APP_CONST} from "../../../../../AppComponents/AppConstants";

import {
    downloadAndViewForm,
    eConsent,
    getIINFormData,
    getAllBankChannelType,
    regenerate,
    submitAcheData,
    setBankMandateChannelType
} from "../../FormsAndReviewActions";
import BankMandateView from "./BankMandateView";
import {getBankData, getBanks, changeForceNewMandate} from "../../../../OnboardingActions";
import store from "../../../../../store";
import {setBankData} from "../../../../onboardingFlowReducer";

const BankMandate = (props) => {

    const {onboardingApplicationId} = props;

    const [bankMandateState, setBankMandateState] = useState({
        formId: '',
        fileName: '',
        filePath: '',
        timeStamp: '',
        generatedTime: '',
        uploadedTime: '',
        isReviewed: false,
        isMarkAsReviewedModalOpen: false,
        isUploadECSFormModalOpen: false,
        isAddRemarkModalOpen: false,
        isViewActivitiesModalOpen: false,
        UIState: APP_CONST.CONTENT_AVAILABLE,
        responseErrors: '',
        isShowLoader: false,
        isFormReviewed: false,
        isECSSubmitted: false,
        electronicConsent: true,
        isManagementUser: false,
        bankMandateStatus: '',
        channelTypeOpt: [],
        selectedChannelType : '',
        bankAccountId: '',
        escFormSubmitStatus: '',
        ACHExpiredDateTime : '',
        allowACH :  '',
        allowEMandate : '',
        allowEMandateAadhaar : '',
        allowEMandateDebitCard : '',
        allowEMandateNetBanking : '',
        bankName: '',
        isECSEditable: false,
        openChangeMandateModal: false
    });

    useEffect(() => {
        getBankChannelType()
    }, [props.generatedFormDetails]);

    const getBankChannelType = () =>{
        const {profile, accountId, bankData} = props;
        getAllBankChannelType(profile?.advisoryId)
            .then(res => {
                if(isEmpty(res?.data?.channelTypes)){
                    getData([],'')
                }else{
                    getBankData(accountId,profile?.advisoryId)
                        .then(bankResData => {
                            if (bankResData?.success) {
                                let channelTypes = res?.data?.channelTypes;
                                let channelTypesFormattedObj = channelTypes.map((item) => {
                                    return {label: item?.["name"], value: item?.["id"]}
                                });
                                if(isEmpty(bankData)) {
                                    getBanks(profile?.advisoryId)
                                        .then(banksRes => {
                                            if (banksRes?.success) {
                                                const banksData = banksRes?.data?.banks;
                                                store.dispatch(setBankData(banksData));
                                                getData(channelTypesFormattedObj, bankResData, banksData)
                                            }
                                        })
                                }else{
                                    getData(channelTypesFormattedObj, bankResData, bankData)
                                }

                            }else{
                                getData([],'',)
                            }
                        });
                }
            })
    };

    const getBankStatusWithName = (bankName, bankData) => {
        for (let obj of bankData) {
            // If the code matches the provided name, return the name from the object
            if (obj.code === bankName) {
                return obj;
            }
        }
        // Return null or a message if no match is found
        return null;
    };

    const removeDisabledChannels = (channelTypeOpt, selectedBankDetails) => {

        return channelTypeOpt.filter(item => {
            if (item.value === "AA" && !selectedBankDetails.allowEMandateAadhaar) {
                return false;
            }
            if (item.value === "DC" && !selectedBankDetails.allowEMandateDebitCard) {
                return false;
            }
            if (item.value === "NET" && !selectedBankDetails.allowEMandateNetBanking) {
                return false;
            }
            return true; // Keep the item if conditions are met
        });

    };

    const getData = (channelTypeOpt, bankResData, bankData) => {
        let escFormSubmitStatus = '';
        let selectedChannelType = '';
        let bankAccountId = '';
        let expiredDateTime = '';
        let selectedBankDetails = getBankStatusWithName(bankResData?.data?.bankName, bankData);
        let channelTypes = removeDisabledChannels(channelTypeOpt, selectedBankDetails);

        if(isEmpty(channelTypes)){
            channelTypes = channelTypeOpt
        }

        if(!isEmpty(bankResData)){
            escFormSubmitStatus= bankResData?.data?.ACHMandateReport?.achMandateStatus;
            selectedChannelType= bankResData?.data?.bankMandate?.channelType;
            bankAccountId= bankResData?.data?.id;
            expiredDateTime= bankResData?.data?.ACHMandateReport?.expiredDateTime;
        }

        const {generatedFormDetails, profile} = props;
        if (isEmpty(generatedFormDetails)) {
            setBankMandateState(prevState => ({
                ...prevState,
                formId: '',
                fileName: '',
                filePath: '',
                timeStamp: '',
                generatedTime: '',
                uploadedTime: '',
                UIState: APP_CONST.CONTENT_AVAILABLE,
                isReviewed: false,
                electronicConsent: true,
                isManagementUser: profile?.isManagement,
                channelTypeOpt: channelTypes,
                bankAccountId: bankAccountId,
                selectedChannelType: selectedChannelType,
                escFormSubmitStatus: escFormSubmitStatus,
                ACHExpiredDateTime: expiredDateTime,
                bankName: selectedBankDetails?.description,
                allowACH :  selectedBankDetails?.allowACH,
                allowEMandate : selectedBankDetails?.allowEMandate,
                allowEMandateAadhaar : selectedBankDetails?.allowEMandateAadhaar,
                allowEMandateDebitCard : selectedBankDetails?.allowEMandateDebitCard,
                allowEMandateNetBanking : selectedBankDetails?.allowEMandateNetBanking,
                isECSEditable: bankResData?.data?.bankMandate?.forceNewMandate,
            }));
        } else {
            let details = sortECSData(generatedFormDetails);
            setBankMandateState((preState) => ({
                ...preState,
                formId: details?.formId,
                fileName: details?.fileName,
                filePath: details?.filePath,
                generatedTime: details?.generationTimestamp,
                uploadedTime: details?.uploadTimestamp,
                timeStamp: isEmpty(details?.updatedAt) ? '' : getTimeFromNow(details?.updatedAt, 'DD-MM-YYYY'),
                UIState: APP_CONST.CONTENT_AVAILABLE,
                isReviewed: details?.isReviewed,
                electronicConsent: details?.eConsent,
                isManagementUser: profile?.isManagement,
                channelTypeOpt: channelTypes,
                bankAccountId: bankAccountId,
                selectedChannelType: selectedChannelType,
                escFormSubmitStatus: escFormSubmitStatus,
                ACHExpiredDateTime: expiredDateTime,
                bankName: selectedBankDetails?.description,
                allowACH :  selectedBankDetails?.allowACH,
                allowEMandate : selectedBankDetails?.allowEMandate,
                allowEMandateAadhaar : selectedBankDetails?.allowEMandateAadhaar,
                allowEMandateDebitCard : selectedBankDetails?.allowEMandateDebitCard,
                allowEMandateNetBanking : selectedBankDetails?.allowEMandateNetBanking,
                isECSEditable: bankResData?.data?.bankMandate?.forceNewMandate,
            }))
        }
    };

    const refreshData = () => {
        const {profile, accountId, updateGeneratedForms} = props;
        let advisoryId = profile?.advisoryId;
        let details = [];
        setBankMandateState((preState) => ({
                ...preState,
                isShowLoader: true,
            }
        ));
        getIINFormData(accountId, advisoryId).then(res => {
            if (res?.success) {
                if (isEmpty(res.data)) {
                    setBankMandateState((preState) => ({
                            ...preState,
                            UIState: APP_CONST.CONTENT_AVAILABLE,
                            isShowLoader: false,
                        }
                    ))
                } else {
                    let details = sortECSData(res?.data?.accountForms);
                    updateGeneratedForms(res?.data?.accountForms);
                    setBankMandateState((preState) => ({
                        ...preState,
                        formId: details?.formId,
                        fileName: details?.fileName,
                        filePath: details?.filePath,
                        generatedTime: details?.generationTimestamp,
                        uploadedTime: details?.uploadTimestamp,
                        timeStamp: isEmpty(details?.updatedAt) ? '' : getTimeFromNow(details?.updatedAt, 'DD-MM-YYYY'),
                        UIState: APP_CONST.CONTENT_AVAILABLE,
                        isReviewed: details?.isReviewed,
                        isShowLoader: false,
                    }))
                }
            } else {
                setBankMandateState(prevState => ({
                    ...prevState,
                    formId: '',
                    fileName: '',
                    filePath: '',
                    timeStamp: '',
                    generatedTime: '',
                    uploadedTime: '',
                    UIState: APP_CONST.CONTENT_UNAVAILABLE,
                    isReviewed: false,
                    isShowLoader: false,
                }));
            }
        })
    };

    const sortECSData = (obj) => {
        let ECSDetails = [];
        if (isEmpty(obj)) {
            return ECSDetails;
        }

        ECSDetails = obj.find((item) => {
            return item?.formType === "ECS";
        });

        return ECSDetails;
    };

    const regenerateForm = () => {
        const {profile, accountId} = props;
        let advisoryId = profile?.advisoryId;

        setBankMandateState((preState) => ({
                ...preState,
                isShowLoader: true,
            }
        ));

        let payload = {
            accountId: accountId,
            onboardingApplicationId: onboardingApplicationId,
            formDetails: {
                ECS: true
            }
        };

        regenerate(payload, advisoryId).then(res => {
            if (res?.success) {
                setBankMandateState((preState) => ({
                        ...preState,
                        isShowLoader: false
                    }
                ))
            } else {
                setBankMandateState(prevState => ({
                    ...prevState,
                    isShowLoader: false
                }));
            }
            refreshData();
        })
    };

    const handleOpenMarkAsReviewCheckBox = () => {
        setBankMandateState((prevState) => ({
            ...prevState,
            isMarkAsReviewedModalOpen: true
        }))
    };

    const handleCloseMarkAsReviewCheckBox = () => {
        setBankMandateState((prevState) => ({
            ...prevState,
            isMarkAsReviewedModalOpen: false,
            responseErrors: '',
        }))
    };

    const setReviewedFlag = () => {
        setBankMandateState((prevState) => ({
            ...prevState,
            isReviewed: true
        }))
    };

    const handleOpenUploadModal = () => {
        setBankMandateState((prevState) => ({
            ...prevState,
            isUploadECSFormModalOpen: true
        }))
    };

    const handleCloseUploadModal = () => {
        setBankMandateState((prevState) => ({
            ...prevState,
            isUploadECSFormModalOpen: false,
            responseErrors: '',
        }));
        refreshData()
    };

    const handleOpenAddRemarkModal = () => {
        setBankMandateState((prevState) => ({
            ...prevState,
            isAddRemarkModalOpen: true,
        }))
    };

    const handleCloseAddRemarkModal = () => {
        setBankMandateState((prevState) => ({
            ...prevState,
            isAddRemarkModalOpen: false,
            responseErrors: '',
        }))
    };

    const selectedOption = (option) => {
        if (option === "Upload") {
            handleOpenUploadModal()
        } else if (option === "Add a Remark") {
            handleOpenAddRemarkModal()
        } else if (option === "View Activities") {
            handleOpenViewActivitiesModal()
        }
    };

    const downloadAndViewDocument = (action) => {
        const {filePath, fileName} = bankMandateState;
        const {profile} = props;
        setBankMandateState((preState) => ({
                ...preState,
                isShowLoader: true,
            }
        ));
        downloadAndViewForm(fileName, filePath, profile?.advisoryId, action);
        setTimeout(() => {
            setBankMandateState((preState) => ({
                    ...preState,
                    isShowLoader: false,
                }
            ))
        }, 500)
    };

    const handleOpenViewActivitiesModal = () => {
        setBankMandateState((prevStep) => ({
            ...prevStep,
            isViewActivitiesModalOpen: true,
        }));
    };

    const handleCloseViewActivitiesModal = () => {
        setBankMandateState((prevStep) => ({
            ...prevStep,
            isViewActivitiesModalOpen: false,
        }));
    };

    const submitECS = () => {
        const {onboardingApplicationId, profile} = props;
        setBankMandateState(prevState => ({
            ...prevState,
            isShowLoader: true
        }));
        let payloadData = {
            onboardingApplicationId: onboardingApplicationId
        };
        submitAcheData(payloadData, profile.advisoryId)
            .then(res => {
                if (res?.success) {
                    setBankMandateState(prevState => ({
                        ...prevState,
                        isShowLoader: false,
                        responseErrors: '',
                        isECSSubmitted: true,
                    }));
                } else {
                    setBankMandateState(prevState => ({
                        ...prevState,
                        isShowLoader: false,
                        isECSSubmitted: false,
                        responseErrors: res?.__error
                    }));
                }
                setTimeout(()=>{
                    setEscFormSubmitStatus()
                }, 500)
            })
    };
    const resetECSSubmissionStatus = () => {

        setBankMandateState(prevState => ({
            ...prevState,
            isECSSubmitted: false
        }));
    };
    const setActivityStatus = (error) => {
        setBankMandateState(prevState => ({
            ...prevState,
            responseErrors: error
        }));
    };

    const setEConsent = (value) => {
        const {profile} = props;
        let advisoryId = profile?.advisoryId;
        setBankMandateState((preState) => ({
                ...preState,
                isShowLoader: true,
            }
        ));

        let payload = {
            id: bankMandateState.formId,
            eConsent: value
        };

        eConsent(payload, advisoryId).then(res => {
            if (res?.success) {
                if(!value){
                    selectChannelType('')
                }
            }
            setTimeout(() => {
                setBankMandateState((preState) => ({
                        ...preState,
                        isShowLoader: false,
                    }
                ))
            }, 500)
        })
    };

    const setElectronicConsent = (value) => {
        setBankMandateState((prevState) => ({
            ...prevState,
            electronicConsent: value
        }));
        setEConsent(value)
    };
    const setBankMandateStatus = (status) =>{
        setBankMandateState((prevState) => ({
            ...prevState,
            bankMandateStatus: status
        }));
    };

    const setEscFormSubmitStatus = () =>{
        const {accountId, profile} = props;
        getBankData(accountId,profile?.advisoryId)
            .then(bankResData => {
                if (bankResData?.success) {
                    setBankMandateState((prevState) => ({
                        ...prevState,
                        escFormSubmitStatus: bankResData?.data?.ACHMandateReport?.achMandateStatus,
                        ACHExpiredDateTime: bankResData?.data?.ACHMandateReport?.expiredDateTime,
                        isECSEditable: bankResData?.data?.bankMandate?.forceNewMandate,
                    }));
                }
            });
    };

    const selectChannelType = (value) =>{
        const {accountId, profile} = props;
        const {bankAccountId, channelTypeOpt, selectedChannelType} = bankMandateState;
        let channelType = channelTypeOpt?.find(dropdownVal=> dropdownVal.value === value);
        channelType = channelType?.value;
        setBankMandateState((preState) => ({
            ...preState,
            isShowLoader: true
        }));

        let payload = {
            accountId: accountId,
            bankAccountId: bankAccountId,
            channelType: channelType
        };
        setBankMandateChannelType(payload,profile?.advisoryId)
            .then(bankResData => {
                if(bankResData?.success){
                    setBankMandateState((preState) => ({
                        ...preState,
                        selectedChannelType: channelType,
                        isShowLoader: false,
                        responseErrors: ''
                    }))
                }else{
                    setBankMandateState((preState) => ({
                        ...preState,
                        selectedChannelType: selectedChannelType,
                        isShowLoader: false,
                        responseErrors: bankResData?.__error
                    }))
                }
            });
    };

    const isEnableIgnoreButton = () =>{
        const {IINNumber} = props;
        const {escFormSubmitStatus, bankMandateStatus, allowEMandate} = bankMandateState;
        return(isEmpty(IINNumber) || (bankMandateStatus === 'ACCEPTED') || (escFormSubmitStatus === 'ACCEPTED'));
    };

    const changeBankMandate = () =>{
        const {profile, accountId} = props;
        const {bankAccountId} = bankMandateState;
        let payload = {
            accountId : accountId,
            bankAccountId: bankAccountId
        };
        setBankMandateState(prevState => ({
            ...prevState,
            isShowLoader: true
        }));

        changeForceNewMandate(payload,profile?.advisoryId)
            .then(response => {
                if (response?.success) {
                    setBankMandateState((preState) => ({
                        ...preState,
                        isShowLoader: false,
                        responseErrors: '',
                        openChangeMandateModal: false
                    }));
                    setTimeout(()=>{
                        setEscFormSubmitStatus()
                    }, 500)
                }else {
                    setBankMandateState((preState) => ({
                        ...preState,
                        responseErrors: response?.__error,
                        isShowLoader: false
                    }));
                }
            });
    };

    const handleOpenChangeMandateModal = () => {
        setBankMandateState((prevStep) => ({
            ...prevStep,
            openChangeMandateModal: true
        }));
    };

    const handleCloseChangeMandateModal = () => {
        setBankMandateState((prevStep) => ({
            ...prevStep,
            openChangeMandateModal: false
        }));
    };


    return (
        <BankMandateView
            {...props}
            {...bankMandateState}
            handleOpenUploadModal={handleOpenUploadModal}
            handleCloseUploadModal={handleCloseUploadModal}
            handleOpenAddRemarkModal={handleOpenAddRemarkModal}
            handleCloseAddRemarkModal={handleCloseAddRemarkModal}
            regenerateForm={regenerateForm}
            handleOpenMarkAsReviewCheckBox={handleOpenMarkAsReviewCheckBox}
            handleCloseMarkAsReviewCheckBox={handleCloseMarkAsReviewCheckBox}
            setReviewedFlag={setReviewedFlag}
            selectedOption={selectedOption}
            downloadAndViewDocument={downloadAndViewDocument}
            handleOpenViewActivitiesModal={handleOpenViewActivitiesModal}
            handleCloseViewActivitiesModal={handleCloseViewActivitiesModal}
            refreshData={refreshData}
            submitECS={submitECS}
            resetECSSubmissionStatus={resetECSSubmissionStatus}
            setActivityStatus={setActivityStatus}
            setElectronicConsent={setElectronicConsent}
            setBankMandateStatus={setBankMandateStatus}
            selectChannelType={selectChannelType}
            setEscFormSubmitStatus={setEscFormSubmitStatus}
            isEnableIgnoreButton={isEnableIgnoreButton}
            changeBankMandate={changeBankMandate}
            handleOpenChangeMandateModal={handleOpenChangeMandateModal}
            handleCloseChangeMandateModal={handleCloseChangeMandateModal}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile,
    isXSView: state.appState.deviceInfo.isXSView,
    isLGView: state.appState.deviceInfo.isLGView,
    accountId: state.onboardingFlowState.accountId,
    bankData: state.onboardingFlowState.bankData
});

export default connect(mapStateToProps, {})(BankMandate);
