import React, {useEffect} from 'react';

import DLInputTextField from "../../../../core/components/DLInputTextField/DLInputTextField";
import DLText from "../../../../core/components/DLText/DLText";
import DLDatePicker from "../../../../core/components/DLDatePicker/DLDatePicker";
import DLButton from "../../../../core/components/DLButton/DLButton";
import DLSearchAutoComplete from "../../../../core/components/DLSearchAutoComplete/DLSearchAutoComplete";
import DLRadioGroup from "../../../../core/components/DLRadioGroup/DLRadioGroup";
import DLLoader from "../../../../core/components/DLLoader/DLLoader";

import {isEmpty} from "../../../../utils/validations";
import {debounce} from "../../../../core/components/DLComponentHelper";
import DLDocUploader from "../../../../core/components/DLDocUploader/DLDocUploader";
import {handleFileError} from "../../../../utils/FileHelper";
import {searchOnWordBoundary} from "../../../../utils/helper";
import appTheme from "../../../../assets/appTheme";

const AadhaarView = (props) => {

    const {
        aadhaarInformation, aadhaarAddress, isMobileView, handleChange, errors, dropdownValues, onNextClick,
        scrollToFieldId, isMDView, isSMView, isXSView, isMultipleApplicants, isFromOnboardingFlow,
    } = props;
    const {aadhaarNumber, dateOfBirth, gender, firstName, middleName, lastName, nameAsPerAadhaar} = aadhaarInformation;
    const {addressLine1, addressLine2, addressLine3, stateCode, city, zipCode, district} = aadhaarAddress;

    useEffect(() => {
        if (!isEmpty(scrollToFieldId)) {
            const element = document.getElementById(scrollToFieldId);
            if (!isEmpty(element)) {
                element.scrollIntoView({behavior: 'smooth', block: 'center'});
                handleScrollChangeWithDebounce()
            }
        }
        return () => {
            window.scrollTo(0, 0)
        }
    }, [scrollToFieldId]);

    const handleScrollChangeWithDebounce = debounce(() => {

        handleChange('scrollToFieldId', '')
    }, 1000);

    const renderButtonGroup = () => {

        const {aadhaarCardType} = props;

        if (isSMView || isXSView) {
            return (
                <div>
                    <DLText id={"select-aadhaar-type"}
                            marginBottom={"none"}
                            fontColor={"grayDark"}
                            fontSize={"xs"}
                            text={"Select Aadhaar Card Type"}/>
                    <div className="mb-3 p-3">
                        <div className='d-flex'>
                            <div>
                                <DLRadioGroup
                                    id={'hours-radioBtn'}
                                    options={[
                                        {
                                            label: 'Single Side',
                                            value: 'SINGLE',
                                        }]}
                                    label={''}
                                    value={aadhaarCardType}
                                    type={'inline'}
                                    labelFontSize={'sm'}
                                    labelFontColor={'black'}
                                    onChangeValue={(e) => {
                                        handleChange('aadhaarCardType', e)
                                    }}
                                    disableHelperMessageHeight={true}
                                    disableDescriptionMessageHeight={true}
                                />
                            </div>
                            <div>
                                <DLRadioGroup
                                    id={'hours-radioBtn'}
                                    options={[
                                        {
                                            label: 'Double Side',
                                            value: 'DOUBLE',
                                        }]}
                                    label={''}
                                    value={aadhaarCardType}
                                    type={'inline'}
                                    labelFontSize={'sm'}
                                    labelFontColor={'black'}
                                    onChangeValue={(e) => {
                                        handleChange('aadhaarCardType', e)
                                    }}
                                    disableHelperMessageHeight={true}
                                    disableDescriptionMessageHeight={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <div className='pt-2 mt-1'>
                <DLText id={"select-aadhaar-type"}
                        marginBottom={"xs"}
                        fontColor={"grayDark"}
                        fontSize={"xs"}
                        text={"Select Aadhaar Card Type"}/>
                <div className="px-2 mb-3">
                    <div>
                        <div className="mb-3" style={{flex: 1}}>
                            <DLRadioGroup
                                id={'hours-radioBtn'}
                                options={[
                                    {
                                        label: 'Single Image',
                                        value: 'SINGLE',
                                    }]}
                                label={''}
                                value={aadhaarCardType}
                                type={'inline'}
                                labelFontSize={'sm'}
                                fontWeight={"semi-bold"}
                                labelFontColor={'black'}
                                onChangeValue={(e) => {
                                    handleChange('aadhaarCardType', e)
                                }}
                                minHeight={"30"}
                                disableHelperMessageHeight={true}
                                disableDescriptionMessageHeight={true}
                            />
                        </div>
                        <div style={{flex: 1}}>
                            <DLRadioGroup
                                id={'hours-radioBtn'}
                                options={[
                                    {
                                        label: 'Two Images (Front and Back Separate)',
                                        value: 'DOUBLE',
                                    }]}
                                label={''}
                                value={aadhaarCardType}
                                type={'inline'}
                                fontWeight={"semi-bold"}
                                labelFontSize={'sm'}
                                labelFontColor={'black'}
                                onChangeValue={(e) => {
                                    handleChange('aadhaarCardType', e)
                                }}
                                minHeight={"30"}
                                disableHelperMessageHeight={true}
                                disableDescriptionMessageHeight={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    const renderDocumentUploader = (type) => {
        const {aadhaarCardType, errors, document, associateDocument, profile, getDownloadImageFileName, aadhaarInformation} = props;

        let currentDocument = {};
        let label: '';
        let errorMessage: '';

        if (aadhaarCardType === 'SINGLE') {
            label = 'Upload Aadhaar Card';
            currentDocument = document?.find(doc => doc.documentCategory === 'COMPLETE');
            errorMessage = isEmpty(errors?.document?.type) || !isEmpty(errors.document?.error) ? errors.document?.error : '';
        } else {
            label = type === 'FRONT' ? 'Upload Aadhaar (Front)' : 'Upload Aadhaar (Back)';
            currentDocument = document?.find(doc => doc.documentCategory === type);
            errorMessage = isEmpty(errors?.document?.type) ? errors.document?.error : (errors.document?.type === type) ? errors.document?.error : ''
        }
        let downloadFileName = getDownloadImageFileName(aadhaarInformation, type, aadhaarCardType)

        return (
            <DLDocUploader
                id={'aadhaar-doc-uploader' + currentDocument.documentCategory}
                history={props.history}
                key={'doc-uploader' + currentDocument.documentCategory}
                label={''}
                boxLabel={label}
                onChangeValue={(docType, value) => {
                    associateDocument(docType, value, type);
                }}
                downloadFileName={isEmpty(downloadFileName) ? currentDocument.fileName : downloadFileName}
                imageHeight={type === 'BACK' ? '503px' : '529px'}
                isShowLabel={false}
                disableHelperMessageHeight={false}
                helperMessage={errorMessage}
                isRequired={true}
                filePath={currentDocument?.filePath}
                uploadedFileName={currentDocument?.fileName}
                documentId={currentDocument?.id}
                advisoryId={profile?.advisoryId}
                isDisableDelete={props.profile?.isManagement}
                handleError={(error) => handleFileError(error, 62914560, 1)}
            />
        );
    };

    const renderUpLoader = () => {
        const {aadhaarCardType} = props;

        return (
            <div className="d-flex flex-column">
                {renderButtonGroup()}

                {!isEmpty(aadhaarCardType) &&
                <div>
                    {renderDocumentUploader('FRONT')}
                    {aadhaarCardType === 'DOUBLE' && renderDocumentUploader('BACK')}
                </div>
                }
            </div>
        );
    };

    const renderTextFieldUi = () => {
        return (
            <div>
                <div className={isMobileView ? 'mx-3' : ''}>
                    {renderAadhaarInformationTextField()}
                </div>
                {isMobileView && <div style={{height: '5px', backgroundColor: appTheme.appColor.gray}}/>}
                <div className={isMobileView ? 'mx-3' : ''}>
                    {renderAddressTextField()}
                </div>
            </div>
        )
    };

    const renderAadhaarInformationTextField = () => {
        return (
            <div className={isMobileView ? '':'me-3'}>
                <div className='section-title'
                     id={'aadhaar-information'}>
                    Aadhaar Information
                </div>
                <div className={isMobileView ? '' : 'panel'}>
                    <div id={'aadhaarInformation-nameAsPerAadhaar-id'}
                         className='d-flex justify-content-start align-items-center'>
                        <div className='flex-grow-1'>
                            <DLInputTextField id={'full-name'}
                                              label={"Name as per Aadhaar"}
                                              value={nameAsPerAadhaar}
                                              maxCharLength={60}
                                              inputType={"text"}
                                              labelFontColor={'grayDark'}
                                              isClearable={false}
                                              isRequired={true}
                                              disableHelperMessageHeight={true}
                                              error={errors.aadhaarInformation.nameAsPerAadhaar !== ''}
                                              helperMessage={errors.aadhaarInformation.nameAsPerAadhaar}
                                              marginBottom={'sm'}
                                              onChangeValue={(e) => {
                                                  handleChange("nameAsPerAadhaar", e, "aadhaarInformation")
                                              }}
                            />
                        </div>
                    </div>
                    <div id={'aadhaarInformation-firstName-id'}
                         className='d-flex justify-content-start align-items-center'>
                        <div className='flex-grow-1'>
                            <DLInputTextField
                                id={''}
                                label={'First Name'}
                                value={firstName}
                                inputType={"text"}
                                isRequired={true}
                                isClearable={false}
                                maxCharLength={20}
                                disableHelperMessageHeight={true}
                                marginBottom={"sm"}
                                error={errors.aadhaarInformation.firstName !== ''}
                                helperMessage={errors.aadhaarInformation.firstName}
                                hideCharLength={true}
                                onChangeValue={(value) => {
                                    handleChange("firstName", value, "aadhaarInformation")
                                }}
                            />
                        </div>
                    </div>
                    <div id={'aadhaarInformation-middleName-id'}
                         className='d-flex justify-content-start align-items-center'>
                        <div className='flex-grow-1'>
                            <DLInputTextField
                                id={''}
                                label={'Middle Name (Optional)'}
                                value={middleName}
                                inputType={"text"}
                                isRequired={true}
                                isClearable={false}
                                maxCharLength={20}
                                disableHelperMessageHeight={true}
                                marginBottom={"sm"}
                                error={errors.aadhaarInformation.middleName !== ''}
                                helperMessage={errors.aadhaarInformation.middleName}
                                hideCharLength={true}
                                onChangeValue={(value) => {
                                    handleChange("middleName", value, "aadhaarInformation")
                                }}
                            />
                        </div>
                    </div>
                    <div id={'aadhaarInformation-lastName-id'}
                         className='d-flex justify-content-start align-items-center'>
                        <div className='flex-grow-1'>
                            <DLInputTextField
                                id={''}
                                label={'Last Name (Optional)'}
                                value={lastName}
                                isRequired={true}
                                isClearable={false}
                                inputType={"text"}
                                maxCharLength={20}
                                disableHelperMessageHeight={true}
                                marginBottom={"sm"}
                                error={errors.aadhaarInformation.lastName !== ''}
                                helperMessage={errors.aadhaarInformation.lastName}
                                hideCharLength={true}
                                onChangeValue={(value) => {
                                    handleChange("lastName", value, "aadhaarInformation")
                                }}
                            />
                        </div>
                    </div>
                    <div id={'aadhaarInformation-aadhaarNumber-id'}
                         className='d-flex justify-content-start align-items-center'>
                        <div className='flex-grow-1'>
                            <DLInputTextField
                                id={''}
                                label={'Aadhaar Number'}
                                value={aadhaarNumber?.trim()}
                                isRequired={true}
                                inputType={"number"}
                                maxCharLength={12}
                                isClearable={false}
                                disableHelperMessageHeight={true}
                                marginBottom={"sm"}
                                error={errors.aadhaarInformation.aadhaarNumber !== ''}
                                helperMessage={errors.aadhaarInformation.aadhaarNumber}
                                hideCharLength={true}
                                onChangeValue={(value) => {
                                    handleChange("aadhaarNumber", value.trim(), "aadhaarInformation")
                                }}
                            />
                        </div>
                    </div>
                    <div id={'aadhaarInformation-dateOfBirth-id'}
                         className='d-flex justify-content-start align-items-center mb-3'>
                        <div className='flex-grow-1'>
                            <DLDatePicker id={"InboxView-FromDate"}
                                          label={"Date of Birth"}
                                          value={dateOfBirth}
                                          isRequired={true}
                                          minWidth={"100%"}
                                          disableHelperMessageHeight={true}
                                          maximumDate={new Date()}
                                          error={errors.aadhaarInformation.dateOfBirth !== ''}
                                          helperMessage={errors.aadhaarInformation.dateOfBirth}
                                          minimumDate={'1900-12-30'}
                                          onChangeValue={(value) => {
                                              handleChange("dateOfBirth", value, "aadhaarInformation")
                                          }}/>
                        </div>
                    </div>
                    <div id={'aadhaarInformation-gender-id'}
                         className='d-flex justify-content-start align-items-center'>
                        <div className='flex-grow-1'>

                            <DLSearchAutoComplete
                                id={'state-dropDown'}
                                label={'Gender'}
                                value={gender}
                                placeholder={"Select"}
                                onSelect={(value) => handleChange("gender", value, "aadhaarInformation")}
                                isSearchable={false}
                                error={errors.aadhaarInformation.gender !== ''}
                                helperMessage={errors.aadhaarInformation.gender}
                                itemNotFoundText={"No options found'"}
                                disableHelperMessageHeight={true}
                                marginBottom={'sm'}
                                isClearable={false}
                                isMulti={false}
                                defaultOptions={dropdownValues.genders}
                                noOptionsMessage={"No options found'"}
                                isRequired={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    const renderAddressTextField = () => {
        return (
            <div className={isMobileView ? '' : 'mt-2 me-3'}>
                <div className='section-title'
                     id={'address'}>
                    Address
                </div>
                <div className={isMobileView ? '' : 'panel'}>
                    <div id={'aadhaarAddress-addressLine1-id'}
                         className='d-flex justify-content-start align-items-center'>
                        <div className='flex-grow-1'>
                            <DLInputTextField
                                id={''}
                                label={'Address Line 1'}
                                value={addressLine1}
                                maxCharLength={80}
                                isRequired={true}
                                isClearable={false}
                                inputType={"text"}
                                disableHelperMessageHeight={true}
                                marginBottom={"sm"}
                                error={errors.aadhaarAddress.addressLine1 !== ''}
                                helperMessage={errors.aadhaarAddress.addressLine1}
                                hideCharLength={true}
                                onChangeValue={(value) => {
                                    handleChange("addressLine1", value, "aadhaarAddress")
                                }}
                            />
                        </div>
                    </div>
                    <div id={'aadhaarAddress-addressLine2-id'}
                         className='d-flex justify-content-start align-items-center'>
                        <div className='flex-grow-1'>
                            <DLInputTextField
                                id={''}
                                label={'Address Line 2 (Optional)'}
                                value={addressLine2}
                                inputType={"text"}
                                isClearable={false}
                                disableHelperMessageHeight={true}
                                maxCharLength={50}
                                marginBottom={"sm"}
                                hideCharLength={true}
                                error={errors.aadhaarAddress.addressLine2 !== ''}
                                helperMessage={errors.aadhaarAddress.addressLine2}
                                onChangeValue={(value) => {
                                    handleChange("addressLine2", value, "aadhaarAddress")
                                }}
                            />
                        </div>
                    </div>
                    <div id={'aadhaarAddress-addressLine3-id'}
                         className='d-flex justify-content-start align-items-center'>
                        <div className='flex-grow-1'>
                            <DLInputTextField
                                id={''}
                                label={'Address Line 3 (Optional)'}
                                value={addressLine3}
                                inputType={"text"}
                                isClearable={false}
                                disableHelperMessageHeight={true}
                                maxCharLength={50}
                                marginBottom={"sm"}
                                hideCharLength={true}
                                error={errors.aadhaarAddress.addressLine3 !== ''}
                                helperMessage={errors.aadhaarAddress.addressLine3}
                                onChangeValue={(value) => {
                                    handleChange("addressLine3", value, "aadhaarAddress")
                                }}
                            />
                        </div>
                    </div>
                    <div id={'aadhaarAddress-city-id'} className='d-flex justify-content-start align-items-center'>
                        <div className='flex-grow-1'>
                            <DLInputTextField
                                id={''}
                                label={'City'}
                                value={city}
                                inputType={"text"}
                                maxCharLength={30}
                                isClearable={false}
                                isRequired={true}
                                disableHelperMessageHeight={true}
                                marginBottom={"sm"}
                                hideCharLength={true}
                                error={errors.aadhaarAddress.city !== ''}
                                helperMessage={errors.aadhaarAddress.city}
                                onChangeValue={(value) => {
                                    handleChange("city", value, "aadhaarAddress")
                                }}
                            />
                        </div>
                    </div>
                    <div id={'aadhaarAddress-zipCode-id'} className='d-flex justify-content-start align-items-center'>
                        <div className='flex-grow-1'>
                            <DLInputTextField
                                id={''}
                                label={'Pincode'}
                                value={zipCode}
                                inputType={"number"}
                                isRequired={true}
                                isClearable={false}
                                maxCharLength={6}
                                disableHelperMessageHeight={true}
                                marginBottom={"sm"}
                                error={errors.aadhaarAddress.zipCode !== ''}
                                helperMessage={errors.aadhaarAddress.zipCode}
                                hideCharLength={true}
                                onChangeValue={(value) => {
                                    handleChange("zipCode", value, "aadhaarAddress")
                                }}
                            />
                        </div>
                    </div>
                    <div id={'aadhaarAddress-district-id'} className='d-flex justify-content-start align-items-center'>
                        <div className={"flex-grow-1"}>
                            <DLInputTextField id={'district-input-field'}
                                              label={'District'}
                                              value={district}
                                              inputType={'text'}
                                              maxCharLength={30}
                                              isClearable={false}
                                              isRequired={true}
                                              error={errors.aadhaarAddress.district !== ''}
                                              helperMessage={errors.aadhaarAddress.district}
                                              disableHelperMessageHeight={true}
                                              marginBottom={'sm'}
                                              onChangeValue={(value) => {
                                                  handleChange("district", value, "aadhaarAddress")
                                              }}
                            />
                        </div>
                    </div>
                    <div id={'aadhaarAddress-stateCode-id'} className='d-flex justify-content-start align-items-center'>
                        <div className={isFromOnboardingFlow ? 'flex-grow-1 mb-4 pb-2' :"flex-grow-1" }>
                            <DLSearchAutoComplete
                                id={'state-dropDown'}
                                label={'State'}
                                value={stateCode}
                                placeholder={"Select"}
                                onSelect={(value) => handleChange("stateCode", value, "aadhaarAddress")}
                                isClearable={false}
                                loadApiData={(input) => searchOnWordBoundary(input, dropdownValues?.states)}
                                isSearchable={true}
                                error={errors.aadhaarAddress.stateCode !== ''}
                                helperMessage={errors.aadhaarAddress.stateCode}
                                itemNotFoundText={"No options found'"}
                                disableHelperMessageHeight={true}
                                marginBottom={'sm'}
                                isMulti={false}
                                dropDownPlacement={"top"}
                                defaultOptions={dropdownValues?.states}
                                noOptionsMessage={"No options found'"}
                                isRequired={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    const renderErrorUI = () => {
        const {responseErrors, totalErrors} = props;

        if (isEmpty(responseErrors) || totalErrors === 0) {
            return '';
        }

        return (
            <div id={'page-level-errors'} className='mb-3 pt-2 mt-1 px-5'>
                {responseErrors.slice(0, 3).map((errorMessage, index) => (
                    <div key={index} className='align-items-center d-flex message-block'>
                        <div className='error-vertical-line'/>
                        <div className='error-panel'>
                            {errorMessage}
                        </div>
                    </div>
                ))}
                {totalErrors > 3 && (
                    <div className='align-items-center d-flex'>
                        {`...${totalErrors - 3} More`}
                    </div>
                )}
            </div>
        );
    };

    const renderButtonUi = () => {
        const {isFromOnboardingFlow, onSaveAndCloseClick} = props;
        return (
            <div className='sticky-bottom-buttons'>
                {isFromOnboardingFlow ?
                    <>
                        <DLButton
                            id={'save-and-close-button'}
                            styleType={"outlined"}
                            borderType={'square'}
                            buttonSize={'sm'}
                            fontSize={'sm'}
                            sideMargin={'none'}
                            onClick={() => {
                                onSaveAndCloseClick()
                            }}
                            label={"Save & Close"}
                        />
                        <DLButton id={'next-button'}
                                  buttonSize={'sm'}
                                  fontSize={'sm'}
                                  sideMargin={'none'}
                                  onClick={() => {
                                      onNextClick()
                                  }}
                                  label={"Next"}/>
                    </>
                    :
                    <DLButton id={'delete-button'}
                              buttonSize={'sm'}
                              fontSize={'sm'}
                              sideMargin={'none'}
                              onClick={() => {
                                  onNextClick()
                              }}
                              label={"Save"}/>
                }
            </div>
        );
    };

    const renderUi = () => {
        if (isMobileView ) {
            return (
                <div >
                    <div className={"mx-3"}>
                         {renderErrorUI()}
                    </div>
                    <div className={"mb-3 mt-2 mx-3"}>
                        {renderUpLoader()}
                    </div>
                    <div>
                        {renderTextFieldUi()}
                    </div>
                </div>
            )
        }

        return (
            <div style={{
                overflowY: 'auto',
                maxHeight: isMultipleApplicants ?
                    !isFromOnboardingFlow ? 'calc(100dvh - 258px)' : 'calc(100dvh - 260px)' :
                    !isFromOnboardingFlow ? 'calc(100dvh - 216px)' : 'calc(100dvh - 218px)'
            }}>
                {renderErrorUI()}
                <div className={"row m-0 p-0" }>
                    <div className='col-6 pe-0 px-5'>
                        {renderUpLoader()}
                    </div>
                    <div className='col-6 p-0 px-5'>
                        {renderTextFieldUi()}
                    </div>
                </div>
            </div>
        )
    };

    return (
        <div>
            {renderUi()}
            <div className='bg-light p-0 m-0 w-100'>
                {renderButtonUi()}
            </div>
            <DLLoader isVisible={props.isLoaderVisible} type={'screen'}/>
        </div>
    );
};

export default AadhaarView;