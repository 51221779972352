import {isEmpty} from "../../utils/validations";
import React from "react";

export const renderTransactionStatusDot = (status) => {
    if (isEmpty(status)) {
        return '-'
    }
    switch (status) {
        case 'OPEN':
            return <span className="status-dot warning"/>;

        case 'COMPLETED':
            return <span className="status-dot success"/>;

        case 'IN_PROGRESS':
            return <span className="status-dot warning"/>;

        case 'DISCARDED':
            return <span className="status-dot danger"/>;
    }
};

export const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
        event.preventDefault();
        document.activeElement?.blur();
    }
};
