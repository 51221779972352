import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";

import {isEmpty} from "../../../../core/components/DLComponentHelper";
import {isAlphabetic, isIncludeSpecialCharacters, isValidEmail} from "../../../../utils/helper";
import {APP_CONST} from "../../../../AppComponents/AppConstants";
import store from "../../../../store";

import {showToastMessage} from "../../../../AppComponents/AppActions";
import {clientsListing, getCategoryOptions, raiseNFTRequest} from "../../NFTAction";

import DLLoader from "../../../../core/components/DLLoader/DLLoader";
import NFTCreateView from "./NFTCreateView";
import {typeOfRequestFilterOptions} from "../../NFTModal";

const NFTCreate = (props) => {

        const [NFTCreateState, setNFTCreateState] = useState({
                requestType: '',
                name: '',
                phoneNumber: '',
                email: '',
                category: '',
                comment: '',
                clients: [],
                keyword: '',
                isOptionSelected: false,
                isApiInProgress: false,
                handleErrorInComponent: true,
                modalErrorMessage: '',
                scrollToFieldId: '',
                UIState: '',
                clientId: '',
                errors: {
                    name: '',
                    phoneNumber: '',
                    email: '',
                    category: '',
                    comment: '',
                    requestType: ''
                },
                dropdownValues: {
                    categoryOptions: [],
                    NFTRequestOptions: []
                }
            }
        );

        useEffect(() => {
            getCategoryValues();
        }, []);

        const handleSearch = (keyword) => {
            const {profile} = props;
            const {advisoryId} = profile;

            const payload = {
                keyword: keyword,
            };

            setNFTCreateState(prevStep => ({
                ...prevStep,
                keyword: keyword,
                isOptionSelected: false
            }));

            if (isEmpty(keyword)) {
                return;
            }

            clientsListing(payload, advisoryId)
                .then((res) => {
                        if (res.success) {
                            setNFTCreateState((prevStep) => ({
                                ...prevStep,
                                clients: res?.data
                            }));
                        } else {
                            setNFTCreateState((prevStep) => ({
                                ...prevStep,
                                clients: []
                            }));
                        }
                    }
                );
        };

        const handleClick = (data) => {
            let mappedState = getMappedData(data)
            setNFTCreateState(prevStep => ({
                ...prevStep,
                ...mappedState,
                keyword: '',
                isOptionSelected: true,
                clientId: data?.accountId
            }));

        };

        const getMappedData = (response) => {
            const {errors} = NFTCreateState;
            if (isEmpty(response) || response?.length === 0) {
                return {...NFTCreateState}
            }
            return {
                name: isEmpty(response?.fullName) ? '' : response?.fullName,
                phoneNumber: isEmpty(response?.phoneNumber) ? '' : response?.phoneNumber,
                email: isEmpty(response?.email) ? '' : response?.email,
                category: isEmpty(response?.category) ? '' : response?.category,
                errors: {
                    ...errors,
                    name: !isEmpty(response?.fullName) ? '' : errors?.fullName,
                    phoneNumber: !isEmpty(response?.phoneNumber) ? '' : errors?.phoneNumber,
                    email: !isEmpty(response?.email) ? '' : errors?.email,
                    category: !isEmpty(response?.category) ? '' : errors?.category,
                },
            };
        };

        const setCategoryValues = (value) => {
            const {dropdownValues} = NFTCreateState;
            const {categoryOptions} = dropdownValues;
            let option = ''
            option = categoryOptions.find((item) => {
                return item.value === value;
            })
            return option?.label;
        }

        const getCategoryValues = () => {
            const {profile} = props;
            const advisoryId = profile?.advisoryId;

            setNFTCreateState(prevState => ({
                ...prevState,
                isLoaderVisible: true
            }));

            getCategoryOptions(advisoryId)
                .then(res => {
                    if (res?.success) {
                        setNFTCreateState(prevState => ({
                            ...prevState,
                            dropdownValues: {
                                ...prevState.dropdownValues,
                                categoryOptions: setValuesForDropdown(res.data?.categoryType, "name", "id"),
                                NFTRequestOptions: setValues(typeOfRequestFilterOptions)
                            },
                            UIState: APP_CONST.CONTENT_AVAILABLE
                        }));
                    } else {
                        setNFTCreateState(prevState => ({
                            ...prevState,
                            isLoaderVisible: false,
                            UIState: APP_CONST.CONTENT_UNAVAILABLE
                        }));
                    }
                })
        };

        const setValuesForDropdown = (data, labelToken, valueToken) => {
            if (isEmpty(data)) {
                return;
            }

            return data.map((item) => {
                return {label: item?.[labelToken], value: item?.[valueToken]}
            });
        };


        const setValues = (data) => {
            if (isEmpty(data)) {
                return;
            }
            return data.filter(item => item.value !== "ALL");
        };

        const validateChanges = (title, compState) => {
            const {name, phoneNumber, email, comment, category, requestType} = compState;
            let errorObj = {...NFTCreateState.errors};

            if (title === "requestType" || title === APP_CONST.FORM_SUBMISSION) {

                if (isEmpty(requestType)) {
                    errorObj.requestType = "Please select request type";
                } else {
                    errorObj.requestType = "";
                }

                if (title === 'requestType') {
                    return errorObj;
                }
            }

            if (title === "name" || title === APP_CONST.FORM_SUBMISSION) {

                if (isEmpty(name)) {
                    errorObj.name = "Please enter name";
                } else if (isIncludeSpecialCharacters(name) || isAlphabetic(name)) {
                    errorObj.name = "Please enter alphabets only";
                } else if (name.length > 30) {
                    errorObj.name = "Maximum 30 characters are allowed";
                } else {
                    errorObj.name = "";
                }

                if (title === 'name') {
                    return errorObj;
                }
            }

            if (title === "phoneNumber" || title === APP_CONST.FORM_SUBMISSION) {
                if (isEmpty(phoneNumber)) {
                    errorObj.phoneNumber = "Please enter phone number";
                } else if (phoneNumber.length !== 10) {
                    errorObj.phoneNumber = "Enter valid phone number";
                } else {
                    errorObj.phoneNumber = "";
                }

                if (title === 'phoneNumber') {
                    return errorObj;
                }
            }

            if (title === "email" || title === APP_CONST.FORM_SUBMISSION) {
                if (isEmpty(email)) {
                    errorObj.email = "Please enter email";
                } else if (!isValidEmail(email, true)) {
                    errorObj.email = "Please enter valid email address";
                } else if (email.length > 50) {
                    errorObj.email = "Maximum 50 characters are allowed";
                } else {
                    errorObj.email = "";
                }
                if (title === 'email') {
                    return errorObj;
                }
            }

            if (title === "comment" || title === APP_CONST.FORM_SUBMISSION) {
                if (isEmpty(comment)) {
                    errorObj.comment = "Please enter comment";
                } else {
                    errorObj.comment = "";
                }

                if (title === 'comment') {
                    return errorObj;
                }
            }

            if (title === "category" || title === APP_CONST.FORM_SUBMISSION) {
                if (isEmpty(category)) {
                    errorObj.category = "Please select category";
                } else {
                    errorObj.category = "";
                }

                if (title === 'category') {
                    return errorObj;
                }
            }

            return errorObj;
        };

        const clearData = () => {
            setNFTCreateState((prevStep) => ({
                ...prevStep,
                requestType: '',
                name: '',
                email: '',
                phoneNumber: '',
                category: '',
                comment: '',
            }));
        };

        const onChangeFormData = (name, value) => {
            if (name === 'scrollToFieldId') {
                setNFTCreateState(prevStep => ({
                    ...prevStep,
                    scrollToFieldId: value
                }));
                return
            }
            let updatedState = {...NFTCreateState};
            updatedState[name] = value;
            updatedState[name + 'Error'] = '';

            const validateObject = validateChanges(name, updatedState);

            setNFTCreateState(prevStep => ({
                ...prevStep,
                ...updatedState,
                modalErrorMessage: '',
                errors: {
                    ...prevStep.errors,
                    ...validateObject
                },
            }));
        };

        const onBlurInputText = (name) => {
            const validateObject = validateChanges(name, NFTCreateState);

            setNFTCreateState(prevStep => ({
                ...prevStep,
                errors: {
                    ...prevStep.errors,
                    ...validateObject
                },
            }));
        };

        const checkIfValidateObj = (validateObj) => {
            const {name, email, phoneNumber, comment, category, requestType} = validateObj;
            if (name || email || phoneNumber || comment || category || requestType) {
                // If any mandatory error is present (non-empty), return false
                return false;
            }
            // If no mandatory errors are present, return true
            return true;
        };


        const handleCreate = () => {
            const {
                name, email, phoneNumber, comment, category, requestType, handleErrorInComponent, clientId
            } = NFTCreateState;
            const {profile} = props;
            const {advisoryId} = profile;

            const validateObj = validateChanges(APP_CONST.FORM_SUBMISSION, NFTCreateState);

            setNFTCreateState((prevStep) => ({
                ...prevStep,
                scrollToFieldId: getFirstErrorFieldId(validateObj)
            }));

            if (!checkIfValidateObj(validateObj)) {
                setNFTCreateState(prevStep => ({
                    ...prevStep,
                    isApiInProgress: false,
                    errors: {
                        ...prevStep.errors,
                        ...validateObj
                    }
                }));
                return;
            }

            const payload = {
                type: "NON_FINANCIAL",
                name: name,
                email: email,
                phoneNumber: phoneNumber,
                category: category,
                requestType: requestType,
                comment: comment,
                accountId: clientId
            };

            setNFTCreateState(prevStep => ({
                ...prevStep,
                isApiInProgress: true
            }));

            raiseNFTRequest(payload, advisoryId, handleErrorInComponent)
                .then((res) => {
                        if (res.success) {
                            clearData();
                            store.dispatch(showToastMessage('success', 'A new request, ' + name + ', has been created'))
                            setNFTCreateState((prevStep) => ({
                                ...prevStep,
                                isApiInProgress: false
                            }));
                            props.history.push('/NF-transactions');
                        } else {
                            setNFTCreateState((prevStep) => ({
                                ...prevStep,
                                isApiInProgress: false,
                                modalErrorMessage: res?.__error
                            }));
                        }
                    }
                );
        };

        function removeErrorWord(input) {
            return input.replace('Error', '');
        }

        const getFirstErrorFieldId = (errors) => {
            for (const section in errors) {
                const error = errors[section];
                if (typeof error === 'string' && error) {
                    return (removeErrorWord(section)) + '-field'; // return the field id (section) where the first error exists
                }
            }
            return null; // return null if no errors found
        };

        const onEnterKeyPress = (event) => {
            if (event?.key === 'Enter') {
                handleCreate();
            }
        };

        if (isEmpty(NFTCreateState.UIState)) {
            return (<DLLoader type={"screen"} isVisible={true}/>);
        }

        return (
            <NFTCreateView
                {...props}
                {...NFTCreateState}
                handleCreate={handleCreate}
                onChangeFormData={onChangeFormData}
                onBlurInputText={onBlurInputText}
                onEnterKeyPress={onEnterKeyPress}
                handleSearch={handleSearch}
                handleClick={handleClick}
                setCategoryValues={setCategoryValues}
            />
        );
    }
;

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile,
    isMDView: state.appState.deviceInfo.isMDView,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isSMView: state.appState.deviceInfo.isSMView,
    isXSView: state.appState.deviceInfo.isXSView,
});

export default connect(mapStateToProps, {})(NFTCreate);
