import { configureStore } from '@reduxjs/toolkit'
import appReducer from "../src/AppComponents/AppReducer";
import userReducer from "./user/userReducer";
import leadsReducer from "./leads/leadsReducer";
import onboardingReducer from "./onboarding/listing/onboardingReducer"
import onboardingFlowReducer from "./onboarding/onboardingFlowReducer"
import clientsReducer from "./clients/components/clientsListing/clientListingReducer"
import NFTReducer from "./transactions/NFT/NFTReducer"


const store = configureStore({
  reducer: {
    appState: appReducer,
    userState: userReducer,
    leadState: leadsReducer,
    onboardingState : onboardingReducer,
    onboardingFlowState : onboardingFlowReducer,
    clientsState : clientsReducer,
    NFTState : NFTReducer
  }
});
export default store;
