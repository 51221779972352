import React from 'react';

import {
    headingData,
    cellConfig,
    typeOfRequestFilterOptions,
    statusFilterOptions
} from "../../../NFTModal";
import {APP_CONST} from "../../../../../AppComponents/AppConstants";
import {handleKeyPress, renderTransactionStatusDot} from "../../../NFTHelper";
import {isEmpty} from "../../../../../core/components/DLComponentHelper";

import emptyLeads from '../../../../../assets/img/empty-leads.png';
import emptyFilter from '../../../../../assets/img/appIcons/emptyFilter.png';
import emptyFilterFilled from '../../../../../assets/img/appIcons/emptyFilterFilled.png';
import filterDot from '../../../../../assets/img/appIcons/filterDot.png';
import clearAllFilter from "../../../../../assets/img/appIcons/Clear Filter.png";

import '../../../../../assets/css/table.css'
import '../../../../../assets/css/listing.css'

import DLTable from "../../../../../core/components/DLTable/DLTable";
import DLText from "../../../../../core/components/DLText/DLText";
import DLLoader from "../../../../../core/components/DLLoader/DLLoader";
import DLButton from "../../../../../core/components/DLButton/DLButton";
import DLDropDown from "../../../../../core/components/DLDropdown/DLDropDown";
import DLDatePicker from "../../../../../core/components/DLDatePicker/DLDatePicker";
import DLInputTextField from "../../../../../core/components/DLInputTextField/DLInputTextField";


const NFTListForCRMView = (props) => {
    const {
        isXSView, listingCount, rowsPerPage, page, handleChangePage, handleChangeRowsPerPage, transactions, NFTFilter,
        isShowLoader, getPaginationNextButtonStatus, skipCount, onChangeFilter, UIState,
        isMobileView
    } = props;

    const {keyword, fromDate, toDate, status, requestType} = NFTFilter;

    const renderHeaderCreateButton = () => {
        const {isMobileView, isXSView} = props;

        if (isXSView) {
            return (
                <div className={'mx-3 my-2'}>
                    <DLButton
                        id={'create-button'}
                        styleType={'outlined'}
                        type={'icon'}
                        padding={'none'}
                        onClick={() => {
                            props.history.push("/createNF-transaction/new")
                        }}
                        startIcon={<i className="fa-solid fa-plus"/>}/>
                </div>
            );
        }
        return (
            <div className={'d-flex justify-content-center pe-2 my-2' + (isMobileView ? ' page-container' : ' mx-4')}>
                <DLButton
                    id={'create-button'}
                    buttonSize={'sm'}
                    reduceWidth={isMobileView}
                    fontSize={'sm'}
                    sideMargin={'none'}
                    onClick={() => {
                        props.history.push("/createNF-transaction/new")
                    }}
                    label={"Raise a Request"}/>
            </div>
        )

    };

    const renderHeader = () => {
        return (
            <div className='d-flex justify-content-between'>
                <div className='page-header'>
                    Non-Financial Transactions
                </div>
                <div>
                    {renderHeaderCreateButton()}
                </div>
            </div>
        )
    };
    const isFiltersApplied = (filters) =>{

        for (let key in filters) {
            if (filters.hasOwnProperty(key)) {
                if (Array.isArray(filters[key])) {
                    if (key === 'status' && filters[key][0] === 'NOT_CONTACTED') {
                        return false
                    }
                    if (filters[key].length > 0) {
                        return true;
                    }
                } else if (filters[key] !== '') {
                    return true;
                }
            }
        }
        return false;
    }

    const renderFilterIcon = () => {
        const {isShowFilterMobile, NFTFilter} = props;
        const filterIcon = isShowFilterMobile ? emptyFilterFilled : emptyFilter;

        if (isFiltersApplied(NFTFilter)) {
            return (
                <div className='icon-container'>
                    <img src={filterIcon} alt={''} height={14} width={14}/>
                    <img className='dot' alt={''} src={filterDot} height={8} width={8}/>
                </div>
            );
        }

        return <img src={filterIcon} alt={''} height={14} width={14}/>;
    };

    const renderFilterMobile = () => {
        const {isShowFilterMobile, handleToggleMobileFilter, resetNFTListing} = props;

        return <div>
            <div className='d-flex align-items-center justify-content-between gap-3 page-container '>
                <div className='search-input' style={{flex: 8}}>
                    <DLInputTextField id={'NFT-searchBox'}
                                      marginBottom={"none"}
                                      rows={1}
                                      styleType={"search"}
                                      onChangeValue={(e) => {
                                          onChangeFilter('keyword', e)
                                      }}
                                      value={keyword}
                                      onKeyPress={handleKeyPress}
                                      placeholder={'Search by Name'}
                                      isSearchable={true}
                                      size="small"
                                      inputHeight={'sm'}
                                      inputType={"text"}
                                      disableHelperMessageHeight={true}

                    />
                </div>
                <div className="d-flex align-items-center">
                    <DLButton
                        id={'filter-button'}
                        styleType={'outlined'}
                        type={'icon'}
                        onClick={() => handleToggleMobileFilter(!isShowFilterMobile)}
                        startIcon={renderFilterIcon()}/>

                    {isFiltersApplied(NFTFilter) &&
                    <div>
                        <i className="fa-solid fa-xmark cursor ms-2 "
                           onClick={() => resetNFTListing()}
                           style={{fontSize: 18}}/>
                    </div>
                    }
                </div>
            </div>
            {isShowFilterMobile &&
            <div className='bg-light mt-2' style={{paddingTop: 10, paddingBottom: 10}}>
                <div className='page-container'>
                    <DLDropDown
                        id={"status-filter"}
                        label={'Status'}
                        labelFontColor={'black'}
                        labelPlacement={'top'}
                        value={status[0]}
                        options={statusFilterOptions}
                        onSelect={(e) => {
                            onChangeFilter('status', e)
                        }}
                        disableHelperMessageHeight={true}
                        minWidth={'100%'}
                        // minWidth={'92dvw'}
                    />
                    <DLDropDown
                        id={"type-of-request-filter"}
                        label={'Type of Request'}
                        labelFontColor={'grayDark'}
                        labelFontSize={'sm'}
                        value={requestType[0]}
                        minWidth={"100%"}
                        options={
                            typeOfRequestFilterOptions
                        }
                        onSelect={(e) => {
                            onChangeFilter('requestType', e)
                        }}
                        disableHelperMessageHeight={true}
                        marginBottom={"none"}
                        labelPlacement={'top'}
                    />
                    <div className='my-1'>{renderDateFilter()}</div>
                </div>
            </div>
            }
        </div>
    };

    const renderDateFilter = () => {
        return (
            <div className='d-flex '>
                <div>
                    <DLDatePicker
                        id={'NFT-from-date'}
                        value={fromDate}
                        label={'From'}
                        labelFontColor={'grayDark'}
                        labelFontSize={'sm'}
                        onChangeValue={(e) => {
                            onChangeFilter('fromDate', e)
                        }}/>
                </div>
                <div className='ms-2'>
                    <DLDatePicker
                        id={'NFT-to-date'}
                        value={toDate}
                        label={'To'}
                        labelFontColor={'grayDark'}
                        labelFontSize={'sm'}
                        // disableHelperMessageHeight={true}
                        onChangeValue={(e) => {
                            onChangeFilter('toDate', e)
                        }}/>
                </div>
            </div>
        )
    };

    const renderClearAllFilterIcon = () => {
        return <img src={clearAllFilter} alt={''} height={14} width={14}/>;
    };

    const getStatusUI = (status) => {
        const {getLabel} = props;

        if (isEmpty(status)) {
            return '-'
        }

        return (
            <div className="d-flex">
                <div className="d-flex justify-content-center align-items-center">
                    <div className="d-flex align-items-center">
                        {renderTransactionStatusDot(status)}
                    </div>
                </div>
                {getLabel(statusFilterOptions, status)}
            </div>
        );
    };

    const renderFilter = () => {
        const {resetNFTListing} = props;
        return (
            <div>
                <div className='d-flex'>
                    <div className='search-input' style={{flex: 1.8}}>
                        <DLInputTextField
                            id={'NFT-list-search-filter'}
                            label={'Search'}
                            labelFontSize={'sm'}
                            labelFontColor={'grayDark'}
                            marginBottom={"none"}
                            rows={1}
                            styleType={"search"}
                            onChangeValue={(e) => {
                                onChangeFilter('keyword', e)
                            }}
                            value={isEmpty(keyword) ? '' : keyword}
                            placeholder={'Search by Name'}
                            isSearchable={true}
                            size="small"
                            inputHeight={'sm'}
                            inputProps={{style: {padding: '7px 10px', maxWidth: "350px"}}}
                            inputType={"text"}
                            disableHelperMessageHeight={true}
                        />
                    </div>
                    <div className='search-input ms-3' style={{flex: 1.8}}>
                        {renderDateFilter()}
                    </div>
                    <div className='select-filter ms-3' style={{flex: 1}}>
                        <DLDropDown
                            id={"type-of-request-filter"}
                            label={'Type of Request'}
                            labelFontColor={'grayDark'}
                            labelFontSize={'sm'}
                            value={requestType[0]}
                            minWidth={"100%"}
                            options={
                                typeOfRequestFilterOptions
                            }
                            onSelect={(e) => {
                                onChangeFilter('requestType', e)
                            }}
                            disableHelperMessageHeight={true}
                            marginBottom={"none"}
                            labelPlacement={'top'}
                        />
                    </div>
                    <div className='select-filter ms-3' style={{flex: 1}}>
                        <DLDropDown
                            id={"status-filter"}
                            label={'Status'}
                            labelFontColor={'grayDark'}
                            labelFontSize={'sm'}
                            value={status[0]}
                            minWidth={"100%"}
                            options={
                                statusFilterOptions
                            }
                            onSelect={(e) => {
                                onChangeFilter('status', e)
                            }}
                            disableHelperMessageHeight={true}
                            marginBottom={"none"}
                            labelPlacement={'top'}
                        />
                    </div>
                    <div className='mt-3 pt-2 ms-2 text-end' style={{flex: 1}}>
                        <DLButton
                            id={'clear-all-filter-button'}
                            styleType={'outlined'}
                            type={'icon'}
                            onClick={() => resetNFTListing()
                            }
                            startIcon={renderClearAllFilterIcon()}/>
                    </div>
                </div>
            </div>
        );
    };

    const emptyList = () => {
        return <div className='d-flex justify-content-center align-items-center gray-lg-text'
                    style={{minHeight: 'calc(100dvh - 132px)', overflow: 'hidden', fontSize: '16px'}}>
            <div className='text-center'>
                <img src={emptyLeads} alt={''}/>
                <DLText id={'create-first-NFT'}
                        text={'Please create your first non financial transaction.'}
                        marginBottom={'md'}
                />
                <DLButton
                    id={'create-button'}
                    buttonSize={'sm'}
                    fontSize={'sm'}
                    sideMargin={'none'}
                    onClick={() => props.history.push("/createNF-transaction/new")}
                    label={'Create Transaction'}/>
            </div>
        </div>
    };

    const renderMobileTable = () => {
        const {isShowFilterMobile} = props;
        let tableData = getFormattedTableData(transactions);

        if (isEmpty(transactions)) {
            return (
                <div className='d-flex align-items-center justify-content-center empty-records'
                     style={{maxHeight: 'calc(100dvh - 315px)', minHeight: 'calc(100dvh - 370px)'}}>
                    No Data found.
                </div>
            )
        }
        return (
            <div className="w-100 pagination-container">
                <DLTable
                    id={''}
                    tableMaxHeight={isShowFilterMobile ? 'calc(100dvh - 500px)' : 'calc(100dvh - 345px)'}
                    tableMinHeight={isShowFilterMobile ? 'calc(100dvh - 500px)' : 'calc(100dvh - 345px)'}
                    isRowClickable={true}
                    onRowClick={() => {
                        // redirectToDetailsScreen(id)
                    }}
                    cellConfig={cellConfig}
                    headerData={headingData}
                    tableData={tableData}
                    pagination={
                        {
                            totalPages: listingCount,
                            rowsPerPage: rowsPerPage,
                            currentPage: page,
                            onPageChange: handleChangePage,
                            onRowPerPageChange: handleChangeRowsPerPage,
                            enableNextButton: getPaginationNextButtonStatus(),
                            enablePrevButton: skipCount === 0

                        }
                    }

                />
            </div>
        )
    };

    const getNameUi = (name, category) => {
        return (
            <div className='py-1'>
                <DLText id={''}
                        text={name}
                        fontWeight={"normal"}
                        fontSize={"xs"}
                        fontColor={""}
                />
                <DLText id={''}
                        text={'(' + category + ')'}
                        fontWeight={"normal"}
                        fontSize={"xs"}
                        fontColor={"grayDark"}
                />
            </div>
        )
    }

    const renderCount = () => {
        const {isXSView, handleRefreshButton} = props;

        if (isXSView) {
            return (
                <div className='page-container mt-3 mb-3'>
                    {!isEmpty(listingCount) &&
                    <div className='d-flex align-items-center'>
                        <DLText id={''}
                                isInline={true}
                                fontColor={'grayDark'}
                                fontSize={'sm'}
                                marginBottom={'none'}
                                fontWeight={'semi-bold'}
                                text={'Showing ' + (listingCount) + ' records'}/>
                        <div className={'d-flex align-items-center mx-2'}
                             onClick={() => {
                                 handleRefreshButton()
                             }}>
                            <i className="fa-solid fa-rotate-right cursor"/>
                        </div>
                    </div>
                    }
                </div>)
        }
        return (
            <div className='py-3'>
                {!isEmpty(listingCount) &&
                <div className={'d-flex align-items-center '}>
                    <DLText id={''}
                            isInline={true}
                            fontColor={'grayDark'}
                            fontSize={'sm'}
                            marginBottom={'none'}
                            fontWeight={'semi-bold'}
                            text={'Showing ' + (listingCount) + ' records'}/>
                    <div className={'d-flex align-items-center mx-2'}
                         onClick={() => {
                             handleRefreshButton()
                         }}>
                        <i className="fa-solid fa-rotate-right cursor"/>
                    </div>
                </div>}
            </div>
        )
    };

    const getFormattedTableData = (transactionData) => {
        let tableData = [];
        const {isXSView, getLabel} = props;
        if (isEmpty(transactionData)) {
            return tableData
        }

        transactionData.map((selectedTransaction) => {

            let transactionRecords = [];
            let transactionDetails = {};
            if (!isXSView) {
                transactionRecords.push({
                    isCustomUI: true,
                    customUI: getNameUi(selectedTransaction?.nftRequest?.name, selectedTransaction?.nftRequest?.category)
                });
                transactionRecords.push(selectedTransaction?.ticketNumber);
                transactionRecords.push(getLabel(typeOfRequestFilterOptions, selectedTransaction?.nftRequest?.nftRequestType));
                transactionRecords.push(selectedTransaction?.openDate);
                transactionRecords.push({
                    isCustomUI: true,
                    customUI: getStatusUI(selectedTransaction?.status)
                });
            } else {
                // Rendering custom ui here for the below column
                transactionRecords.push({
                    isCustomUI: true,
                    customUI: getNameUi(selectedTransaction?.nftRequest?.name, selectedTransaction?.nftRequest?.category)
                });
                transactionRecords.push(selectedTransaction?.ticketNumber);
                transactionRecords.push(getLabel(typeOfRequestFilterOptions, selectedTransaction?.nftRequest?.nftRequestType));
                transactionRecords.push(selectedTransaction?.openDate);
                transactionRecords.push({
                    isCustomUI: true,
                    customUI: getStatusUI(selectedTransaction?.status)
                });
            }

            transactionDetails.id = selectedTransaction?.id;
            transactionDetails.data = transactionRecords;
            tableData.push(transactionDetails)
        });

        return tableData

    };

    const renderDesktopTable = () => {
        const {isShowFilterMobile} = props;
        let tableData = getFormattedTableData(transactions);


        if (isEmpty(transactions)) {
            return (
                <div className='w-100 d-flex align-items-center justify-content-center panel'
                     style={{maxHeight: 'calc(100dvh - 315px)', minHeight: 'calc(100dvh - 360px)'}}>
                    No Data found.
                </div>
            )
        }
        return (
            <div className="w-100">
                <DLTable
                    id={''}
                    tableMaxHeight={isShowFilterMobile ? 'calc(100dvh - 500px)' : 'calc(100dvh - 340px)'}
                    tableMinHeight={isShowFilterMobile ? 'calc(100dvh - 500px)' : 'calc(100dvh - 340px)'}
                    isRowClickable={true}
                    onRowClick={() => {
                        // redirectToDetailsScreen(id)
                    }}
                    cellConfig={cellConfig}
                    headerData={headingData}
                    tableData={tableData}
                    pagination={
                        {
                            totalPages: listingCount,
                            rowsPerPage: rowsPerPage,
                            currentPage: page,
                            onPageChange: handleChangePage,
                            onRowPerPageChange: handleChangeRowsPerPage,
                            enableNextButton: getPaginationNextButtonStatus(),
                            enablePrevButton: skipCount === 0

                        }
                    }

                />
            </div>
        )
    };

    const renderNFTTable = () => {
        return (
            <div className={'w-100 mt-3' + ((isMobileView && !isXSView) ? '  page-container' : '')}>
                {renderCount()}
                {isXSView ?
                    renderMobileTable()
                    :
                    <div>
                        {renderDesktopTable()}
                    </div>
                }
            </div>
        )
    };

    const renderLoader = () => {
        return (<DLLoader type={"screen"} isVisible={isShowLoader}/>);
    };

    if (UIState === APP_CONST.CONTENT_UNAVAILABLE) {
        return (
            <div className='page-container'>
                {emptyList()}
            </div>
        );
    }

    if (isMobileView) {
        return (
            <div>
                <div className={'w-100'} style={{
                    background: 'rgba(0, 0, 0, 0.02)'
                }}>
                    {renderHeader()}
                    {renderFilterMobile()}
                    {renderNFTTable()}
                    {renderLoader()}
                </div>
            </div>
        );
    }

    return (
        <div className={'h-100 d-flex justify-content-center'}>
            <div className={'w-100'}>
                {renderHeader()}
                <div className='page-container pt-1'>
                    {renderFilter()}
                </div>
                <div className='page-container bg-light'
                     style={{minHeight: 'calc(100dvh - 185px)'}}>
                    {renderNFTTable()}
                </div>
                {renderLoader()}
            </div>
        </div>
    );
};

export default NFTListForCRMView;