import {httpGet, httpPost} from "../../utils/httpHelper";
import store from "../../store";
import {
    setSearchKeywordValue, setFromDateValue, setToDateValue,
    setStatusValue, resetAllFilters, setLimitCountValue,
    setPageValue, setRowsPerPageValue, setSkipCountValue, setTypeOfRequestValue
} from "./NFTReducer"

export const getTransactionListing = (data, advisoryId) => {
    let URL = '/service/advisory/nftTransaction/all';

    return httpPost(URL, data, advisoryId)
        .then(res => {
            return res;
        });
};

export const raiseNFTRequest = (data, advisoryId,handleErrorInComponent) => {
    return httpPost('/service/advisory/nftTransaction', data, advisoryId, handleErrorInComponent).then(res => {
        return res;
    })
};

export const clientsListing = (data, advisoryId,handleErrorInComponent) => {
    return httpPost('/service/advisory/person/search', data, advisoryId, handleErrorInComponent).then(res => {
        return res;
    })
};

export const getCategoryOptions = (advisoryId) => {
    return httpGet('/service/advisory/categoryType/all',advisoryId).then(res => {
        return res;
    });
};

export const setSearchKeyword = (data) => {
    store.dispatch(setSearchKeywordValue(data))
};

export const setFromDate = (data) => {
    store.dispatch(setFromDateValue(data))
};

export const setToDate = (data) => {
    store.dispatch(setToDateValue(data))
};

export const setStatus = (data) => {
    store.dispatch(setStatusValue(data))
};

export const setTypeOfRequest = (data) => {
    store.dispatch(setTypeOfRequestValue(data))
};

export const resetNFTFilters = (data) => {
    store.dispatch(resetAllFilters(data))
};

export const setSkipCount = (data) => {
    store.dispatch(setSkipCountValue(data))
};

export const setLimitCount = (data) => {
    store.dispatch(setLimitCountValue(data))
};

export const setPageCount = (data) => {
    store.dispatch(setPageValue(data))
};

export const setLeadsRowPerPageCount = (data) => {
    store.dispatch(setRowsPerPageValue(data))
};