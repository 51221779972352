import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";

import {isEmpty} from "../../../../../../utils/validations";
import {getOnboardingDetails} from "../../../../../OnboardingActions";
import {getOnboardingStatus} from "../../../../../OnboardingHelper";
import {onboardingStatuses} from "../../../../../OnboardingConsts";

import {getBankMandateActivity, sendToECSClient} from "../../../FormsAndReviewActions";
import BankMandateActivityView from "./bankMandateActivityView";
import store from "../../../../../../store";
import {showToastMessage} from "../../../../../../AppComponents/AppActions";
import {setACHFormStatus} from "../../../../../onboardingFlowReducer";

const BankMandateActivity = (props) => {

    const [bankMandateActivity, setBankMandateActivityState] = useState({
        NSEActivities: [],
        isShowLoader: false,
        NSEStatus: '',
        NSEButtonStatus: '',
        responseErrors: '',
        achMandateStatus: ''
    });

    useEffect(() => {
        getStatus();
    }, []);

    useEffect(() => {
        const {isRefershACHForm} = props;
        if(isRefershACHForm){
            refreshNSEActivities();
            store.dispatch(setACHFormStatus(false));
        }
    }, [props.isRefershACHForm]);

    useEffect(() => {
        const {isECSSubmitted, resetECSSubmissionStatus} = props;
        if(isECSSubmitted){
            getStatus();
            setTimeout(()=>{
                resetECSSubmissionStatus()
            },1000)
        }

    }, [props.isECSSubmitted]);

    const getStatus = () => {
        const {profile, onboardingApplicationId} = props;
        setBankMandateActivityState(prevState => ({
            ...prevState,
            isShowLoader: true,
        }));

        getOnboardingDetails(onboardingApplicationId, profile.advisoryId)
            .then((res) => {
                if (res?.success) {
                    let achMandateStatus = '';
                    if(isEmpty(res?.data?.account?.bankAccounts)){
                        props.setBankMandateStatus('PENDING')
                    }else{
                        achMandateStatus = res?.data?.account?.bankAccounts[0]?.ACHMandateReport?.achMandateStatus;
                        props.setBankMandateStatus(achMandateStatus)
                    }
                    getSubmitButtonStatus(res?.data?.status, achMandateStatus);
                } else {
                    getBankMandateActivities('');
                    setBankMandateActivityState(prevState => ({
                        ...prevState,
                        isShowLoader: false,
                    }));
                }
            })
    };

    const getSubmitButtonStatus = (status, achMandateStatus) => {
        if (isEmpty(status)) {
            getBankMandateActivities(achMandateStatus);
            return ''
        }
        let NSEStatus = getOnboardingStatus(status, onboardingStatuses);

        if (status === 'DRAFT' || status === 'NOT_READY_FOR_SUBMISSION') {
            setBankMandateActivityState(prevState => ({
                ...prevState,
                isShowLoader: false,
                NSEStatus: NSEStatus,
                NSEButtonStatus: 'HIDE',
            }));
        }
        if (status === 'SUBMISSION_IN_PROGRESS' || status === 'AWAITING_CONFIRMATION' || status === 'CONFIRMED') {
            setBankMandateActivityState(prevState => ({
                ...prevState,
                isShowLoader: false,
                NSEStatus: NSEStatus,
                NSEButtonStatus: 'DISABLE',
            }));
        }
        if (status === 'SUBMISSION_FAILED' || status === 'ERRORED' || status === 'READY_FOR_SUBMISSION') {
            setBankMandateActivityState(prevState => ({
                ...prevState,
                isShowLoader: false,
                NSEStatus: NSEStatus,
                NSEButtonStatus: 'SHOW',
            }));
        }
        setTimeout(() => {
            getBankMandateActivities(achMandateStatus);
            props.handleChangeOnboardingStatus(status);
        }, 500);
    };

    const getBankMandateActivities = (achMandateStatus) => {
        const {onboardingApplicationId, profile, setActivityStatus} = props;
        setBankMandateActivityState(prevState => ({
            ...prevState,
            isShowLoader: true
        }));
        getBankMandateActivity(onboardingApplicationId, profile.advisoryId)
            .then(res => {
                if (res?.success) {
                    setBankMandateActivityState(prevState => ({
                        ...prevState,
                        NSEActivities: res.data,
                        isShowLoader: false,
                        achMandateStatus: achMandateStatus
                        // responseErrors: ''
                    }));
                    setActivityStatus('');
                } else {
                    setBankMandateActivityState(prevState => ({
                        ...prevState,
                        NSEActivities: [],
                        isShowLoader: false,
                        achMandateStatus: achMandateStatus
                        // responseErrors: res?.__error,
                    }));
                    setActivityStatus(res?.__error)
                }
            })
    };

    const sendToClient = (activityId) => {
        const {onboardingApplicationId, profile, setActivityStatus} = props;
        setBankMandateActivityState(prevState => ({
            ...prevState,
            isShowLoader: true
        }));
        let payloadData = {
            onboardingApplicationId: onboardingApplicationId,
            activityId: activityId
        };
        sendToECSClient(payloadData, profile.advisoryId)
            .then(res => {
                if (res?.success) {
                    store.dispatch(showToastMessage('success', 'eMandate link is sent to client'));
                    setBankMandateActivityState(prevState => ({
                        ...prevState,
                        isShowLoader: false,
                    }));
                    setActivityStatus('')
                } else {
                    setBankMandateActivityState(prevState => ({
                        ...prevState,
                        isShowLoader: false,
                    }));
                    setActivityStatus(res?.__error)
                }
                getStatus()
            })
    };

    const refreshNSEActivities = () =>{
        getStatus();
        props.setEscFormSubmitStatus()
    };

    const getIsLinkExpired = () =>{
        const {ACHExpiredDateTime} = props;
        if(isEmpty(ACHExpiredDateTime)){
            return false
        }
        const targetDate = new Date(ACHExpiredDateTime);

        // Get the current date and time
        const currentDate = new Date();

        // Compare the current date and time with the target date
        return currentDate >= targetDate;
    };

    return (
        <BankMandateActivityView
            {...props}
            {...bankMandateActivity}
            refreshNSEActivities={refreshNSEActivities}
            sendToClient={sendToClient}
            getIsLinkExpired={getIsLinkExpired}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isXLView: state.appState.deviceInfo.isXLView,
    isXSView: state.appState.deviceInfo.isXSView,
    accountId: state.onboardingFlowState.accountId,
    accountType: state.onboardingFlowState.accountType,
    isRefershACHForm: state.onboardingFlowState.isRefershACHForm,
    ECSSubmitStatus: state.onboardingFlowState.ECSSubmitStatus,
    profile: state.userState.profile
});

export default connect(mapStateToProps, {})(BankMandateActivity);
