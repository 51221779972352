import {isEmpty} from "../utils/validations";
import React from "react";
import appTheme from "../assets/appTheme";
import DLText from "../core/components/DLText/DLText";

export const setValuesForDropdown = (data, labelToken, valueToken) => {
    if (isEmpty(data)) {
        return;
    }
    let obj = [];

    obj = data.map((item) => {
        return {label: item?.[labelToken], value: item?.[valueToken]}
    });
    return obj
};

export const renderOnboardingStatusDot = (status) => {
    if (isEmpty(status)) {
        return '-'
    }
    switch (status) {
        case 'ACTIVATED' :
            return <span className="status-dot success"/>;

        case 'ACCEPTED':
            return <span className="status-dot success"/>;

        case 'ERRORED':
            return <span className="status-dot danger"/>;

        case 'NOT_REGISTERED':
            return <span className="status-dot danger"/>;

        default:
            return <span className="status-dot warning"/>;
    }
};

export const renderKycStatusIcon = (status) => {
    if (isEmpty(status)) {
        return '-'
    }
    switch (status) {
        case false:
            return <i className="fa-solid fa-check kyc-display-status-icon success d-flex align-items-center justify-content-center rounded-circle" style={{color:appTheme.appColor.white}}/>;
        default:
            return <i className="fa-solid fa-exclamation kyc-display-status-icon warning d-flex align-items-center justify-content-center rounded-circle" style={{color:appTheme.appColor.white}}/>;
    }
};

export const formatDataForDropDown = (data) => {
    if (isEmpty(data)) {
        return {}
    }
    return data.map(item => ({
        label: item.name,
        value: item.id
    }));
};

export const getFullName = (first_name, middle_name, last_name) => {
    if(isEmpty(first_name) && isEmpty(middle_name) && isEmpty(last_name))
    {
        return '-';
    }
    const firstName = first_name || "";
    const middleName = middle_name || "";
    const lastName = last_name || "";
    return `${firstName} ${middleName} ${lastName}`.trim().replace(/\s+/g, ' ');
};

export const getOnboardingStatus = (selectedStatus, onboardingStatuses) => {
    if(isEmpty(selectedStatus) || isEmpty(onboardingStatuses)){
        return '-'
    }
    const status = onboardingStatuses.find(status => status.value === selectedStatus);
    return status ? status.label : '-';
};


export const getBankMndateFormatedStatus = (status) => {
    if (isEmpty(status) || status==='-') {
        return '-'
    }
    switch (status) {
        case 'NOT_REGISTERED':
            return (
                <div className={"d-flex"}>
                    <div>
                        {renderOnboardingStatusDot(status)}
                    </div>
                    <div>
                        <DLText id={''}
                                isInline={true}
                                fontSize={'xs'}
                                fontWeight={'normal'}
                                text={'Not Registered'}/>
                    </div>
                </div>
            );

        case 'ACCEPTED':
            return (
                <div className={"d-flex"}>
                    <div>
                        {renderOnboardingStatusDot(status)}
                    </div>
                    <div>
                        <DLText id={''}
                                isInline={true}
                                fontSize={'xs'}
                                fontWeight={'normal'}
                                text={'Accepted'}/>
                    </div>
                </div>
            );
        case 'PENDING':
            return (
                <div className={"d-flex"}>
                    <div>
                        {renderOnboardingStatusDot(status)}
                    </div>
                    <div>
                        <DLText id={''}
                                isInline={true}
                                fontSize={'xs'}
                                fontWeight={'normal'}
                                text={'Pending'}/>
                    </div>
                </div>
            );
        case 'REJECTED':
            return (
                <div className={"d-flex"}>
                    <div>
                        {renderOnboardingStatusDot('ERRORED')}
                    </div>
                    <div>
                        <DLText id={''}
                                isInline={true}
                                fontSize={'xs'}
                                fontWeight={'normal'}
                                text={'Rejected'}/>
                    </div>
                </div>
            );

        default:
            return <div className={"d-flex"}>
                <div>
                    {renderOnboardingStatusDot(status)}
                </div>
                <div>
                    <DLText id={''}
                            isInline={true}
                            fontSize={'xs'}
                            fontWeight={'normal'}
                            text={status}/>
                </div>
            </div>;
    }
};

